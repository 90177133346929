import './style.scss';

import { DocumentBasylic } from "../../types/types";
import { FC } from 'react';
import ToggleIdNumber from '../ToggleIdNumber';
import { formatTypeDocument } from '../../assets/functions/'

type DocumentPreviewProps = {
  document: DocumentBasylic;
};

const DocumentPreview: FC<DocumentPreviewProps> = ({ document }) => {
  return (
    <div className="DocumentPreview">
      {document && (
        <>
          <div className="DocumentPreview__header">
            <h4 className="DocumentPreview__header__title">{formatTypeDocument(document.typeDocument)}</h4>
            <ToggleIdNumber number={document?.numeroDocument} />
          </div>
          <div className="DocumentPreview__content">
            <div>
              <p>{document?.prenom} {document?.nom}, {document?.sexe}</p>
              <div className="row gap-1">
                <div className="col-sm-6">
                  <p className='label'>Date de naissance:</p>
                  <p>{document?.dateNaissance}</p>
                </div>
              </div>
              <div className="row gap-1">
                {document?.dateDelivrance && (
                  <div className="col-6">
                    <p className='label'>Date de delivrance:</p>
                    <p>{document?.dateDelivrance}</p>
                  </div>
                )}
                {document?.dateExpiration && (
                  <div className="col-6">
                    <p className='label'>Date d'expiration:</p>
                    <p>{document?.dateExpiration}</p>
                  </div>
                )}
              </div>
            </div>
            <div className='DocumentPreview__nationality'>
              <img src={`${process.env.PUBLIC_URL}/img/flags/fr.png`} alt={`drapeau français`} className="flag" />
              FR
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default DocumentPreview;
