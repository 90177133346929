import axios from "../../axiosConfig";

export async function invitation(email,type) {
	const body = new FormData();
	body.append("email",email)
	body.append("type",type)
	const response = await axios.post("/client/email/invitation",body);
	return response;

}
export async function shareFile(email,file,type) {
	const body = new FormData();
	body.append("email",email)
	body.append("file",file)
	body.append("type",type)
	const response = await axios.post("client/email/invitation/file",body);
	return response;
}

export async function documentRequest(documentId,type) {
	const response = await axios.post("/client/documentrequests",{
		documentId,
		type
	});
	return response;
}

export async function documentConfirm(requestId, status) {
	const body = new FormData();
	body.append("requestId",requestId)
	body.append("status",status)
	const response = await axios.post("/client/documentrequests/confirm",body);
	return response;
}

export async function documentRequestResponse(id,type) {
	const response = await axios.post("/client/documentrequests/handshake",{ id,type });
	return response;
}
