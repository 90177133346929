import { FC, useContext, useState } from "react";

import { DocumentUnion } from "../types/types";
import { DocumentsContext } from "../contexts/DocumentsContext";
import PageHeader from "../components/PageHeader";
import TabContent from "../components/TabContent";
import Tabs from "../components/Tabs";

const ListIDs: FC = () => {
	const [selectedRows, setSelectedRows] = useState<DocumentUnion[]>([]);
	const { followed, lostDocuments, allDocuments } = useContext(
		DocumentsContext
	);

	const handleSelectionChange = (rows: DocumentUnion[]) => {
		setSelectedRows(rows);
	};

	const tabs = [
		{
			label: "Tous",
			notification: false,
			content: (
				<TabContent
					documents={allDocuments}
					selectedRows={selectedRows}
					onSelectionChange={handleSelectionChange}

				/>
			),
		},
		{
			label: "Actifs",
			notification: false,
			content: (
				<TabContent
					documents={followed}
					selectedRows={selectedRows}
					onSelectionChange={handleSelectionChange}

				/>
			),
		},
		{
			label: "Inactifs",
			notification: false,
			content: (
				<TabContent
					documents={lostDocuments}
					selectedRows={selectedRows}
					onSelectionChange={handleSelectionChange}

				/>
			),
		},
	];

	return (
		<>
			<PageHeader>
				<h5>Pièces d'identité</h5>
			</PageHeader>
			<div className="content__body">
				<Tabs tabs={tabs} />
			</div>
		</>
	);
};


export default ListIDs;
