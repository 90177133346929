import { FolderContext } from "../contexts/FoldersContext";
import FolderList from "../components/FolderList";
import NotificationsList from "../components/NotificationsList";
import PageHeader from "../components/PageHeader";
import Tabs from "../components/Tabs";
import { useContext } from "react";

const IDLockerList = () => {
    const { folders, sharedFolders } = useContext(FolderContext);

    const tabs = [
        {
            label: "Tous",
            notification: false,
            content: <FolderList folders={[...folders, ...sharedFolders]} />,
        },
        {
            label: "Mes dossiers",
            notification: false,
            content: <FolderList folders={folders} />,
        },
        {
            label: "Dossiers partagés avec moi",
            notification: false,
            content: (
                <FolderList
                    folders={sharedFolders}
                    canCreateFolder={false}
                />
            ),
        },
    ];

    return (
        <div>
            <PageHeader>
                <h5>ID Locker</h5>
            </PageHeader>
            <div className="content__body">
                <div className="row">
                    <div className="col-lg-9 ">
                        <Tabs tabs={tabs} />
                    </div>
                    <div className="col-lg-3">
                        <NotificationsList filter="folder" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IDLockerList;
