import AccompaniementsList from "../components/AccompaniementsList/AccompaniementsList";
import { FC } from "react";
import HeaderSection from "../components/HeaderSection";
import IDAssistantCard from "../components/IDAssistantCard";
import NotificationsList from "../components/NotificationsList";
import PageHeader from "../components/PageHeader";
const Accompagnements: FC = () => {
    // const handleAddAccompaniment = async () => {
    //     try {
    //         await createAccompaniment(type);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };
    return (
        <>
            <PageHeader>
                <h5>ID Assistant</h5>
            </PageHeader>
            <div className="content__body">
                <div className="row">
                    <div className="col-lg-9">
                        <HeaderSection title="Accompagnements en cours" />
                        <AccompaniementsList />
                        <div>
                            <IDAssistantCard />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <NotificationsList />
                    </div>
                </div>
            </div>
        </>
    );
};
export default Accompagnements;
