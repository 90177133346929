import { FC, useContext } from "react";

import { CREDITS_PRICING } from "../constants/pricings";
import CreditsPricingCard from "../components/CreditsPricingCard";
import PageHeader from "../components/PageHeader";
import ProfilCard from "../components/ProfilCard/ProfilCard";
import logoIdTracker from "../assets/img/ID Tracker.svg";
import { useNavigate } from "react-router-dom";

const CreditsPricing: FC = () => {
    const navigate = useNavigate();
    const handleNext = (planIndex: number) => {
        navigate(`/particuliers/payment/credits/${planIndex}/onetime`);
    };

    return (
        <div className="CreditsPricing">
            <PageHeader>
                <h4>CREDIT DE VERIFICATION</h4>
            </PageHeader>
            <div className="content__body">
                <ProfilCard
                    title="Facturation"
                    logo={logoIdTracker}>
                    <h5>TROUVER le plan pour l’achat de vos credit de verification</h5>
                    <div className="row mb-1">
                        {CREDITS_PRICING.map((plan, index) => (
                            <div className="col-sm-4">
                                <CreditsPricingCard
                                    handleNext={handleNext}
                                    plan={plan}
                                    planIndex={index}
                                />
                            </div>
                        ))}
                    </div>
                </ProfilCard>
            </div>
        </div>
    );
};
export default CreditsPricing;
