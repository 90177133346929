import axios from "../../axiosConfig";

export async function getFollowedDocuments( userType) {
  const url = '/client/followeddocuments/get-documents';
  const body = new FormData();
  body.append('type', userType);
  try {
    const response = await axios.post(url, body);
    return response.data;
  } catch (error) {
    return error;
  }
}

export function addFollowingDocument(userType,data) {
	data = Object.assign(data,{ type: userType })
	const url = '/client/followeddocuments/';
	return axios.post(url,data);
}
export function updateStateDocument(userType,documents) {

  let data = {
    type: userType,
    documents
  }

	const url = '/client/followeddocuments/state';
	return axios.put(url,data);
}
