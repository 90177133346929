import './style.scss';

import React, { FC, useContext, useState } from 'react';

import Alert from '../Alert';
import Input from '../Input';
import Modal from '../Modal/Modal';
import { UserContext } from "../../contexts/UserContext";
import { checkPassword } from '../../api/auth';
import { deleteAccount } from '../../api/user';

type DeleteDocumentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: (type: string, clientNumber: number) => void;
};
const DeleteAccountModal: FC<DeleteDocumentModalProps> = ({ isOpen, onClose }) => {
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const { user } = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const type = user?.type;
  const handlePasswordChange = async () => {
    const response = await checkPassword(user?.id, password, type);
    setIsPasswordValid(response.data ? true : false);
  };


  const handleDelete = async () => {
    try {
      await handlePasswordChange();
      if (password && isPasswordValid) {
        try {
          await deleteAccount(type);
          setSuccessMessage("Compte supprimé avec succès.");
          setTimeout(() => {
            handleClose();

          }, 1000);
          setTimeout(() => {
            window.location.href = "/";
          }, 1500);
        } catch (error) {
          console.log(error);
        }
      } else {
        setErrorMessage("Mot de passe incorrect");
      }
    } catch (error: any) {
      setIsPasswordValid(false);
      setErrorMessage(error.response.data.message);
    }
  }
  const handleClose = () => {
    onClose();
    setPassword("");
    setErrorMessage("");
    setSuccessMessage("");
    setIsPasswordValid(true);
  }

  return (
    <>
      {isOpen && (
        <Modal
          title='Supprimer votre compte'
          isOpen={isOpen}
          confirmButtonDisabled={!isPasswordValid || !password}
          onConfirm={handleDelete}
          onClose={onClose}
        >
          {(errorMessage && isPasswordValid) && <Alert type="error"  > {errorMessage} </Alert>}
          {successMessage && <Alert type="success"> {successMessage} </Alert>}
          <p className="mb-1">Vous êtes sur le point de supprimer votre compte sur ID Protect. Toute suppression est définitive et toutes vos informations et pièces d'identité seront supprimées de notre base de données.</p>
          <p className="mb-1">Veuillez confirmer votre mot de passe.</p>
          <Input
            placeholder="Mot de passe"
            type="password"
            value={password}
            onChange={(e) => { setPassword(e.target.value); setIsPasswordValid(true); setErrorMessage(""); }}
            errorMessage={!isPasswordValid && password ? "Mot de passe incorrect" : ""}
            className={!isPasswordValid && password ? "error" : ""}
          />
        </Modal>
      )}
    </>
  );
}

export default DeleteAccountModal;
