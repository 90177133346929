import "./style.scss";

import Accordion from "../Accordion";
import { FC } from "react";

const FileDownloadInstructions: FC = () => {
    return (
        <div className="FileDownloadInstructions">
            <Accordion title="Si vous utilisez votre téléphone">
                <ol>
                    <li>Déposez la carte d'identité à plat sur une feuille blanche (assurez-vous d'être dans une pièce bien éclairée)</li>
                    <li>Enlevez tous les stickers et autres objets collés sur le document qui pourraient entraver sa lecture.</li>
                    <li>
                        Prenez en photo ou scannez à l'aide de votre téléphone de manière à bien voir apparaître le document d'identité dans
                        son intégralité
                    </li>
                    <li>Répétez la même opération pour le verso.</li>
                </ol>
            </Accordion>
            <Accordion title="Si vous utilisez un ordinateur">
                <ol>
                    <li>Scannez le document avec un scanner bureautique.</li>
                    <li>Enregistrer le ensuite sur la plateforme.</li>
                </ol>
                <small>
                    NB : L'outil que nous vous mettons à disposition est développé à partir des pratiques bancaires. Il permet de confirmer
                    l'authenticité des documents d'identité. La qualité de l'image jouant un rôle important dans sa vérification, nous vous
                    conseillons de privilégier une version scannée de vos documents d'identité.
                </small>
            </Accordion>
        </div>
    );
};

export default FileDownloadInstructions;
