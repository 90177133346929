import "./style.scss";

import { Button_Style, Button_Type } from '../Button/Button';
import { FC, useState } from "react";

import Alert from "../Alert";
import Button from "../Button";
import Loader from "../Loader";
import { resend } from "../../api/user";
import { useNavigate } from 'react-router-dom';

interface ConfirmEmailProps {
  confirmEmailMessage: string;
}
const ConfirmEmail: FC<ConfirmEmailProps> = ({ confirmEmailMessage }) => {
  const navigate = useNavigate();
  const regex = /[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,4}/;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const checkEmail = async () => {
    setIsLoading(true);
    try {
      // use match method to find emails
      const emailsFounded = confirmEmailMessage.match(regex);
      if (emailsFounded) {
        const email = emailsFounded[0];
        const action = "v"
        const response = await resend(email, action);
        setIsLoading(false);
        setSuccessMessage("Un nouveau lien de confirmation vous a été envoyé par email.");
      }
    } catch (error: any) {
      setErrorMessage(error.response.data.error);
      setIsLoading(false);
    }
  };

  return (
    <div className="ConfirmEmail">
      <div>
        <h5>Espace Particulier</h5>
        <h2>Confirmez votre email</h2>
        <p>
          {confirmEmailMessage} Si vous n'avez rien reçu, vérifiez vos courriers
          indésirables ou le dossier « Notifications » de votre boite mail.
        </p>
      </div>
      {errorMessage && <Alert type="error">{errorMessage}</Alert>}
      {successMessage && <Alert type="success">{successMessage}</Alert>}
      {isLoading && <Loader />}
      <div className="d-flex gap-1 flex-column">
        <Button label="Connexion" type={Button_Type.PRIMARY} onClick={() => navigate(`/particuliers/login/`)} className="m-auto" />
        <Button
          label="Renvoyer le lien"
          type={Button_Type.PRIMARY}
          style={Button_Style.OUTLINED}
          onClick={() => checkEmail()}
        />

      </div>
    </div>
  );
};

export default ConfirmEmail;
