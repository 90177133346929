import "./style.scss";

import { Button_Style, Button_Type } from "../Button/Button";
import { FC, useContext, useEffect, useRef, useState } from "react";

import Alert from "../Alert";
import Button from "../Button";
import { ReactComponent as IconDowload } from "../../assets/img/icons/icon_Download.svg";
import { ReactComponent as IconSend } from "../../assets/img/icons/icon_Send.svg";
import ModalSendFile from "../ModalSendFile";
import { PDFDocument } from "pdf-lib";
import { UserContext } from "../../contexts/UserContext";

type ImageFilterProps = {
    imageSrc: File;
    watermarkSrc: string;
    watermarkPDF: string;
};

const ImageFilter: FC<ImageFilterProps> = ({ imageSrc, watermarkSrc, watermarkPDF }) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [filteredPDFURL, setFilteredPDFURL] = useState<string | null>(null);
    const [filteredFile, setFilteredFile] = useState<File | null>(null);

    const { userType } = useContext(UserContext);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const urlToFile = async (url: string, filename: string, mimeType: any) => {
        const res = await fetch(url);
        const blob = await res.blob();
        return new File([blob], filename, { type: mimeType });
    };

    const loadImage = async (file: File | string): Promise<HTMLImageElement> => {
        const image = new Image();
        await new Promise((resolve, reject) => {
            image.onload = resolve;
            image.onerror = reject;

            if (typeof file === "string") {
                image.src = file;
            } else {
                const reader = new FileReader();

                reader.onload = (event) => {
                    image.src = event.target?.result as string;
                };
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            }
        });
        return image;
    };

    const downloadImage = () => {
        const downloadLink = document.createElement("a");
        let urlToDownload: string;
        let extension: string;
        if (imageSrc.type === "application/pdf") {
            urlToDownload = filteredPDFURL ? filteredPDFURL : URL.createObjectURL(imageSrc);
            extension = "pdf";
        } else {
            const canvas = canvasRef.current;
            urlToDownload = canvas?.toDataURL("image/png") as string;
            extension = "png";
            if (!canvas) {
                return;
            }
        }

        downloadLink.href = urlToDownload;
        downloadLink.download = `${imageSrc.name}-ID_Protect.${extension}`;
        downloadLink.click();
    };

    const applyWatermarkToPDF = async (pdfFile: File, watermarkImageSrc: string) => {
        const pdfBuffer = await pdfFile.arrayBuffer();
        let pdfDoc: PDFDocument;
        try {
            pdfDoc = await PDFDocument.load(pdfBuffer);
        } catch (error) {
            // get file name
            const fileName = pdfFile.name;
            console.error("Error loading PDF:", error);
            setErrorMessage(`Le fichier ${fileName} est encrypté et ne peut être modifié.`);
            return;
        }

        const watermarkImageBytes = await fetch(watermarkImageSrc).then((res) => res.arrayBuffer());
        const watermarkImage = await pdfDoc.embedPng(watermarkImageBytes);

        const pages = pdfDoc.getPages();
        for (const page of pages) {
            const { width, height } = page.getSize();
            page.drawImage(watermarkImage, { x: 0, y: 0, width, height });
        }

        const pdfBytes = await pdfDoc.save();
        const objectURL = URL.createObjectURL(new Blob([pdfBytes], { type: "application/pdf" }));
        setFilteredPDFURL(objectURL);

        return objectURL;
    };

    const applyWatermark = async () => {
        try {
            const [image, watermark] = await Promise.all([loadImage(imageSrc), loadImage(watermarkSrc)]);
            const canvas = canvasRef.current;
            if (canvas) {
                const context = canvas.getContext("2d");
                if (context) {
                    canvas.width = image.width;
                    canvas.height = image.height;

                    context.drawImage(image, 0, 0);

                    const watermarkSize = Math.min(canvas.width, canvas.height) * 1;
                    const watermarkX = (canvas.width - watermarkSize) / 2;
                    const watermarkY = (canvas.height - watermarkSize) / 2;
                    context.drawImage(watermark, watermarkX, watermarkY, watermarkSize, watermarkSize);

                    canvas.toBlob((blob) => {
                        if (blob) {
                            const file = new File([blob], `${imageSrc.name}-ID_Protect.png`, { type: "image/png" });
                            setFilteredFile(file);
                        }
                    }, "image/png");
                }
            }
        } catch (error) {
            console.error("Error applying watermark:", error);
        }
    };

    useEffect(() => {
        let extension = imageSrc.type === "application/pdf" ? "pdf" : "png";

        if (filteredPDFURL) {
            // If it's a PDF, use the existing method
            urlToFile(filteredPDFURL, `${imageSrc.name}-ID_Protect.${extension}`, imageSrc.type).then((file) => {
                setFilteredFile(file);
            });
        }
    }, [filteredPDFURL, imageSrc.name, imageSrc.type, canvasRef]);

    useEffect(() => {
        if (imageSrc.type === "application/pdf") {
            applyWatermarkToPDF(imageSrc, watermarkPDF).then((pdfURL) => {});
        } else {
            applyWatermark();
        }
    }, [imageSrc, watermarkPDF]);

    return (
        <>
            {errorMessage && <Alert type="error">{errorMessage}</Alert>}
            {!errorMessage && (
                <div className="ImageFilter">
                    {imageSrc.type === "application/pdf" ? (
                        <div className="thumbnail-pdf">
                            <iframe
                                src={`${filteredPDFURL}#toolbar=0`}
                                width="100%"
                                height="100%"
                                title="Filtered PDF"
                            />
                        </div>
                    ) : (
                        <div className="thumbnail">
                            <canvas ref={canvasRef} />
                        </div>
                    )}
                    <div className="d-flex gap-1">
                        <Button
                            onClick={downloadImage}
                            style={Button_Style.OUTLINED}
                            type={Button_Type.PRIMARY}
                            isIconButton
                            icon={<IconDowload width={16} />}
                        />
                        <Button
                            onClick={() => setShowModal(true)}
                            style={Button_Style.OUTLINED}
                            type={Button_Type.PRIMARY}
                            isIconButton
                            disabled={!userType}
                            icon={<IconSend width={16} />}
                        />
                    </div>
                    {showModal && (
                        <ModalSendFile
                            onClose={() => setShowModal(false)}
                            isOpen={showModal}
                            file={filteredFile}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default ImageFilter;
