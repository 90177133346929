import "./style.scss";

import { FC, useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as IconLogout } from "../../assets/img/icons/icon_logout.svg";
import { ReactComponent as IconUser } from "../../assets/img/icons/icon_User.svg";
import { UserContext } from "../../contexts/UserContext";

type MenuProfileProps = {
    isCollapsed: boolean;
};
const MenuProfile: FC<MenuProfileProps> = ({ isCollapsed }) => {
    const { token, user, logoutFonction, userType } = useContext(UserContext);
    const urlType = userType === "partner" ? "/professionnels/" : "/particuliers/";
    const location = useLocation();

    return (
        <ul className="aside__profile">
            <li className={location.pathname === `${urlType}profil` ? "active" : ""}>
                <Link
                    to={`${urlType}profil`}
                    className="aside__button">
                    <div className="aside__item align-items-top">
                        <IconUser
                            fill={"#fff"}
                            width="16px"
                            height={16}
                        />
                        {!isCollapsed && (
                            <div className="aside__item__content">
                                <span>{user?.firstname + " " + user?.lastname}</span>
                                <p>{user?.email}</p>
                            </div>
                        )}
                    </div>
                </Link>
            </li>
            {(token || user) && (
                <li onClick={logoutFonction}>
                    <div className="aside__button">
                        <div className="aside__item">
                            <IconLogout
                                fill={"#fff"}
                                width="16px"
                                height={40}
                            />
                            {!isCollapsed && <span>Deconnexion</span>}
                        </div>
                    </div>
                </li>
            )}
        </ul>
    );
};

export default MenuProfile;
