import axios from "../../axiosConfig";

export async function getLostDocuments(type) {
  try {
    const response = await axios.post('/client/get_lost_documents', { type });

    return response.data.results;
  } catch (error) {
    return error;
  }
}

export async function checkDocument(number, type) {
	let data = {
		type,
		number
	}
	const response = await axios.post("/client/search_lost_document",data);
    if (response) {
      return response.data;
    }
}

export async function saveLostDocument(
type,
	document,
	file,
status
) {
	console.log(status)
	const url = '/client/save_lost_documents/from/followedDocument';
	const body = new FormData();
	body.append("type",type);
	body.append("number",document.number);
	body.append("pv",file);
	body.append("id",file);
	body.append("status",status);
	return axios.post(url,body);
}

export async function postLostDocument(
	type,
	document,
	file
) {
	console.log(document.numeroDocument, file)
		const url = '/client/save_lost_documents';
		const body = new FormData();
		body.append("type",type);
		body.append("idNumber",document.numeroDocument);
	body.append("docType",document.docType);
		body.append("pv",file);
		body.append("id",file);
		return axios.post(url,body);

}

export async function searchLostDocument(type, docType, idNumber) {
  try {
    const body = new FormData();
    body.append("type", type);
    body.append("docType", docType);
    body.append("number", idNumber);
    const response = await axios.post("/client/search_lost_document", body);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
}

export function deleteDocument(userType, documents) {
  let data = {
    type: userType,
    documents
  }

	const url = '/client/deleteDocument';
	let body = data
	return axios.post(url,body);
}

export function getDocumentByNumber(numeroDocument,type) {
  let data = {
	type,
	  numeroDocument
  }

	const url = '/client/getDocumentByNumber';
	let body = data
	return axios.post(url,body);
}
