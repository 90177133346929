import './style.scss';
import '@react-pdf-viewer/core/lib/styles/index.css';

import { FC } from 'react';
import PDFThumbnailPlugin from '../PDFThumbnailPlugin';
import { Viewer } from '@react-pdf-viewer/core';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';

interface DisplayThumbnailExampleProps {
  fileUrl: string;
  pageIndex: number;
  width: number;
}

const PDFCover: FC<DisplayThumbnailExampleProps> = ({ fileUrl, pageIndex, width }) => {
  const thumbnailPluginInstance = thumbnailPlugin();
  const { Cover } = thumbnailPluginInstance;
  const pageThumbnailPluginInstance = PDFThumbnailPlugin({
    PageThumbnail: <Cover getPageIndex={() => pageIndex} width={width} />,
  });

  return <Viewer fileUrl={fileUrl} plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]} />;
}

export default PDFCover;
