import { useContext, useState } from "react";

import { ASSISTANCE_PRICING } from "../constants/pricings";
import DocumentIllustration from "../components/DocumentIllustration";
import IDAssistantComment from "../components/IDAssistantComment";
import IDAssistantInfoForm from "../components/IDAssistantInfoForm";
import IDAssistantPricing from "../components/IDAssistantPricing";
import IDAssistantSelectDocument from "../components/IDAssistantSelectDocument";
import IDAssistantStepperMenu from "../components/IDAssistantStepperMenu/IDAssistantStepperMenu";
import IDAssistantUploadPV from "../components/IDAssistantUploadPV";
import ResultAfterPayment from "../components/ResultAfterPayment";
import StripePaymentCard from "../components/StripePaymentCard";
import { UserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";

export default function IDAssistantAdd() {
    const navigate = useNavigate();

    const { user } = useContext(UserContext);
    const [message, setMessage] = useState("");
    const [title, setTitle] = useState("");
    const [paymentSucceeded, setPaymentSucceeded] = useState(false);
    const [datas, setDatas] = useState<any>({
        user: user,
        addresse: "",
        postalCode: "",
        city: "",
        comment: "",
        perteId: "",
        documents: [],
        pv: [],
        chosenPlan: ASSISTANCE_PRICING[1],
    });

    const [steps, setSteps] = useState([
        { isCurrent: true, isDone: false },
        { isCurrent: false, isDone: false },
        { isCurrent: false, isDone: false },
        { isCurrent: false, isDone: false },
        { isCurrent: false, isDone: false },
        { isCurrent: false, isDone: false },
        { isCurrent: false, isDone: false },
    ]);

    // For Skip the step in order to go to payment
    /*// Function to update steps 0 to 3
    const updateSteps = () => {
        setSteps((prevSteps) =>
            prevSteps.map((step, index) => ({
                ...step,
                isCurrent: index == 4, // Set true for indices 0 to 3,
                isDone: index >= 0 && index <= 3, // Set true for indices 0 to 3,
            }))
        );
    };

    // Call the update function when needed, for example in a useEffect
    useEffect(() => {
        updateSteps();
    }, []);*/

    const handleUpdateStep = (step: number) => {
        setSteps(
            steps.map((s, index) => {
                if (index === step) {
                    return { isCurrent: true, isDone: false };
                } else if (index < step) {
                    return { isCurrent: false, isDone: true };
                }
                return { isCurrent: false, isDone: false };
            }),
        );
    };

    const handleNextStep = () => {
        const currentStep = steps.findIndex((step) => step.isCurrent);
        if (currentStep < steps.length - 1) {
            handleUpdateStep(currentStep + 1);
        }
    };

    const handlePreviousStep = () => {
        const currentStep = steps.findIndex((step) => step.isCurrent);
        if (currentStep > 0) {
            handleUpdateStep(currentStep - 1);
        }
    };

    const handlePaymentResult = (title: string, message: string, paymentSucceeded: boolean) => {
        handleNextStep();
        setTitle(title);
        setMessage(message);
        setPaymentSucceeded(paymentSucceeded);
    };

    return (
        <div className="IDAssistantAdd h-100">
            <div className="content__body h-100">
                <div className="row">
                    <div className="col-sm-1">
                        <IDAssistantStepperMenu
                            steps={steps}
                            setSteps={setSteps}
                            handleUpdateStep={handleUpdateStep}
                        />
                    </div>
                    <div className={`col-sm-6`}>
                        <div className="content--card">
                            {steps[0].isCurrent && (
                                <IDAssistantSelectDocument
                                    setDatas={setDatas}
                                    datas={datas}
                                    steps={steps}
                                    handleNextStep={handleNextStep}
                                    handlePreviousStep={handlePreviousStep}
                                />
                            )}
                            {steps[1].isCurrent && (
                                <IDAssistantUploadPV
                                    setDatas={setDatas}
                                    datas={datas}
                                    steps={steps}
                                    handleNextStep={handleNextStep}
                                    handlePreviousStep={handlePreviousStep}
                                />
                            )}
                            {steps[2].isCurrent && (
                                <IDAssistantInfoForm
                                    setDatas={setDatas}
                                    datas={datas}
                                    steps={steps}
                                    handleNextStep={handleNextStep}
                                    handlePreviousStep={handlePreviousStep}
                                />
                            )}

                            {steps[3].isCurrent && (
                                <IDAssistantComment
                                    setDatas={setDatas}
                                    datas={datas}
                                    steps={steps}
                                    handleNextStep={handleNextStep}
                                    handlePreviousStep={handlePreviousStep}
                                />
                            )}
                            {steps[4].isCurrent && (
                                <IDAssistantPricing
                                    setDatas={setDatas}
                                    datas={datas}
                                    steps={steps}
                                    handleNextStep={handleNextStep}
                                    handlePreviousStep={handlePreviousStep}
                                />
                            )}
                            {steps[5].isCurrent && (
                                <StripePaymentCard
                                    title={`Accompagnement ${datas.chosenPlan.title}`}
                                    link={`particuliers/id-assistant`}
                                    setDatas={setDatas}
                                    datas={datas}
                                    steps={steps}
                                    handlePaymentResult={handlePaymentResult}
                                    handlePreviousStep={handlePreviousStep}
                                />
                            )}
                            {steps[6].isCurrent && (
                                <ResultAfterPayment
                                    title={title}
                                    message={message}
                                    paymentSucceeded={paymentSucceeded}
                                    steps={steps}
                                    handlePreviousStep={handlePreviousStep}
                                    handleNextStep={() => navigate("/particuliers/id-assistant")}
                                    textBtn={"Accéder à mon dossier"}
                                />
                            )}
                        </div>
                    </div>
                    <div className={`col-sm-5 center`}>
                        <div>
                            {/*<h3 className="text-center mb-4">Remplir mon dossier d'accompagnement</h3>*/}
                            <DocumentIllustration type={"payment"} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
