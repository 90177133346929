import "./style.scss";

import React, { FC, useContext, useEffect } from "react";

import { UserContext } from "../../contexts/UserContext";

type ProfilCardProps = {
  title: string;
  logo: string;
  name?: string;
  children: React.ReactNode;
};
const ProfilCard: FC<ProfilCardProps> = ({ title, logo, name, children }) => {

  const { user } = useContext(UserContext)

  useEffect(() => {
    // console.log(user)
  }, [user])

  return (
    <div className="ProfilCard">
      <div className="ProfilCard__header">
        <div className="ProfilCard__header__title">
          <img src={logo} alt="" />
          <h4>{title}</h4>
        </div>
        {name && <h3>{name}</h3>}
      </div>
      <div className="ProfilCard__body">{children}</div>
    </div>
  );
};

export default ProfilCard;
