import './style.scss';

import { DocumentUnion } from "../../types/types";
import { FC } from 'react';
import { ReactComponent as Lock } from "../../assets/img/icons/icon_Lock.svg";
import NationalityBadge from '../NationalityBadge';
import ToggleIdNumber from '../ToggleIdNumber';
import { formatTypeDocument } from '../../assets/functions/'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

type DocumentCardProps = {
  document: DocumentUnion;
};
const DocumentCard: FC<DocumentCardProps> = ({ document }) => {
  const navigate = useNavigate();
  const handleDocumentClick = (id: number) => {
    navigate(`/mes-documents/${id.toString()}`);
  };
  return (
    <div className="DocumentCard" onClick={() => handleDocumentClick(document.id)}>
      <div className='DocumentCard__header'>
        <div className='d-flex gap-1'>
          <p className="DocumentCard__type">{formatTypeDocument(document.type)}</p>
          <NationalityBadge />
        </div>
        <div className='d-flex gap-1 align-items-center'>
          <span className={`tag ${document.status === 'SUIVI' ? 'tag-success' : 'tag-error'}`}>
            {document.status}
          </span>
          {document.isBlocked ? <Lock fill="#9FC1EF" width={16} height={16} /> : <span style={{ width: '16px', height: '16px' }}></span>}
        </div>
      </div>
      <ToggleIdNumber number={document.number} />
      <p className="DocumentCard__date"><span className="DocumentCard__date__label">Date de l'ajout: </span>{moment(document.createdAt).format("DD/MM/YYYY")}</p>
    </div>
  );
}

export default DocumentCard;
