import './style.scss';

import { FC, useContext, useState } from 'react';

import AddFolderSVG from '../../assets/img/Addfolder.svg';
import EmptyContent from '../EmptyContent';
import FolderCard from '../FolderCard';
import { FolderContext } from '../../contexts/FoldersContext';
import { FolderType } from '../../types/types';
import Input from '../Input';
import Modal from '../Modal';
import { useLocation } from 'react-router-dom';

type FolderListProps = {
  folders: FolderType[];
  canCreateFolder?: boolean;
};
const FolderList: FC<FolderListProps> = ({ folders, canCreateFolder = "true" }) => {
  const { tryCreateFolder } = useContext(FolderContext);
  const location = useLocation();
  const pathname = location.pathname.split('/').slice(2).join('/');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [folderName, setFolderName] = useState('');
  return (
    <>
      <div className="Folder_List">
        {canCreateFolder && <div className="Folder_List_item new" onClick={() => setModalIsOpen(true)}>
          <img src={AddFolderSVG} alt="dossier" />
          <div className="Folder_List_item_title">Nouveau</div>
        </div>}
        {folders && folders.length > 0 ? folders.map((folder: FolderType) => (
          <FolderCard key={folder.id} name={folder.name} id={folder.id} folder={folder} pathname={pathname} ></FolderCard>
        )) :
          <EmptyContent text="Aucun dossiers" />
        }
      </div>
      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          title="Créer un dossier"
          onConfirm={async () => {
            await tryCreateFolder(folderName);
            setModalIsOpen(false);
          }}
        >
          <Input placeholder='Nom du dossier' onChange={(e) => setFolderName(e.target.value)} />
        </Modal>
      )}
    </>
  );
}

export default FolderList;
