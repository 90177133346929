import "./style.scss";

import Button, { Button_Style, Button_Type } from "../Button/Button";
import { FC, useEffect, useState } from "react";

import Checkbox from "../Checkbox/Checkbox";
import { DocumentUnion } from "../../types/types";
import EmptyContent from "../EmptyContent";
import { ReactComponent as IconNext } from "../../assets/img/icons/icon_carret-Right.svg";
import { ReactComponent as IconPrev } from "../../assets/img/icons/icon_carret-Left.svg";
import { ReactComponent as Lock } from "../../assets/img/icons/icon_Lock.svg";
import NationalityBadge from "../NationalityBadge";
import React from "react";
import ToggleIdNumber from "../ToggleIdNumber/ToggleIdNumber";
import moment from "moment";
import { useNavigate } from "react-router-dom";

type TableColumn = {
    key: string;
    label: string;
};

type TableProps = {
    columns: TableColumn[];
    rows: DocumentUnion[];
    selectable?: boolean;
    onSelectionChange: (selectedRows: DocumentUnion[]) => void;
    selectedRows: DocumentUnion[];
    editable?: boolean;
    size?: string;
    className?: string;
};

const ITEMS_PER_PAGE = 10;

const Table: FC<TableProps> = ({ columns, rows, selectedRows, selectable = false, onSelectionChange, editable, size, className }) => {
    const navigate = useNavigate();
    const [selectAll, setSelectAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(rows.length / ITEMS_PER_PAGE);

    // Get rows for the current page
    const getVisibleRows = () => {
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, rows.length);
        return rows.slice(startIndex, endIndex);
    };

    useEffect(() => {
        if (onSelectionChange) {
            onSelectionChange(selectedRows);
        }
        // set selectedRows to empty array when no rows are selected
        if (selectedRows.length === 0) {
            setSelectAll(false);
        }
    }, [selectedRows, rows]);

    useEffect(() => {
        if (onSelectionChange) {
            onSelectionChange(selectedRows);
        }
    }, [selectedRows, rows]);

    // Fonction pour formater les données en fonction de leur clé
    const formatData = (key: string, data: any, id: number) => {
        const formatDocumentType = (value: string) => {
            switch (value) {
                case "id":
                    return "Carte d'identité";
                case "id_card":
                    return "Carte d'identité";
                case "passeport":
                    return "Passeport";
                case "passport":
                    return "Passeport";
                case "permis":
                    return "Permis";
                case "driver_permit":
                    return "Permis";
                case "sejour":
                    return "Carte de séjour";
                case "residency_permit":
                    return "Carte de séjour";
                default:
                    return "Inconnu";
            }
        };

        switch (key) {
            case "nationality":
                return (
                    <div onClick={() => handleDocumentClick(id)}>
                        <NationalityBadge nationality={data} />
                    </div>
                );
            case "type":
                return (
                    <div
                        className="td--type"
                        onClick={() => handleDocumentClick(id)}>
                        {formatDocumentType(data)}
                    </div>
                );
            case "number":
                return (
                    <div className="td--idNumber">
                        <ToggleIdNumber number={data} />
                    </div>
                );
            case "createdAt":
                return (
                    <div
                        className="td--createdAt"
                        onClick={() => handleDocumentClick(id)}>
                        {moment(data).format("DD/MM/YYYY")}
                    </div>
                );

            case "status":
                return (
                    <span
                        className={`tag ${data === "SUIVI" ? "tag-success" : "tag-error"}`}
                        onClick={() => handleDocumentClick(id)}>
                        {data}
                    </span>
                );
            case "isBlocked":
                return (
                    <div
                        className="td--isBlocked"
                        onClick={() => handleDocumentClick(id)}>
                        {data ? (
                            <Lock
                                fill="#9FC1EF"
                                width={12}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                );
            default:
                return data;
        }
    };

    const handleRowSelect = (document: DocumentUnion, checked: boolean) => {
        const { id } = document;
        if (selectable) {
            let newSelectedRows: DocumentUnion[] = [];
            if (checked) {
                newSelectedRows = [...selectedRows, document];
            } else {
                newSelectedRows = selectedRows.filter((row) => row.id !== id);
            }
            onSelectionChange(newSelectedRows);
        }
    };

    const handleSelectAll = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        // Si newSelectAll est true, sélectionnez toutes les lignes, sinon videz les sélections
        onSelectionChange(newSelectAll ? rows : []);
    };

    const handleDocumentClick = (id: number) => {
        navigate(`/mes-documents/${id.toString()}`);
    };

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <>
            {rows.length > 0 ? (
                <table className={`table table--${selectable && "selectable"} ${className && className} ${size && "table--" + size}`}>
                    {editable && (
                        <thead>
                            <tr>
                                {selectable && (
                                    <th>
                                        <Checkbox
                                            onChange={() => handleSelectAll()}
                                            checked={selectAll}
                                        />
                                    </th>
                                )}
                                {columns.map((column) => (
                                    <th
                                        key={column.key}
                                        className={column.key === "creationDate" ? "text-center" : ""}>
                                        {column.label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                    )}
                    <tbody>
                        <tr>
                            <td
                                colSpan={columns.length}
                                style={{ height: 10, opacity: 0 }}
                            />
                        </tr>
                        {getVisibleRows().map((row: DocumentUnion, index: number) => (
                            // Utilisation de la fragment React pour regrouper les éléments
                            <React.Fragment key={index}>
                                <tr>
                                    {selectable && (
                                        <td>
                                            <Checkbox
                                                onChange={(e) => {
                                                    handleRowSelect(row, e.target.checked);
                                                }}
                                                checked={selectedRows.includes(row)}
                                            />
                                        </td>
                                    )}
                                    {columns.map((column: TableColumn) => (
                                        <td key={column.key}>{formatData(column.key, row[column.key as keyof DocumentUnion], row.id)}</td>
                                    ))}
                                </tr>

                                <tr>
                                    <td
                                        colSpan={columns.length}
                                        style={{ height: 10, opacity: 0 }}
                                    />
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            ) : (
                <EmptyContent text="Vous n'avez aucun document enregistrés" />
            )}
            {totalPages > 1 && (
                <div className="pagination">
                    <Button
                        onClick={goToPreviousPage}
                        disabled={currentPage === 1}
                        icon={<IconPrev fill="#1956A8" />}
                        style={Button_Style.OUTLINED}
                        type={Button_Type.PRIMARY}
                    />
                    <Button
                        onClick={goToNextPage}
                        disabled={currentPage === totalPages}
                        icon={<IconNext fill="#1956A8" />}
                        style={Button_Style.OUTLINED}
                        type={Button_Type.PRIMARY}
                        isIconButton
                    />
                    <span>
                        Page {currentPage} sur {totalPages}
                    </span>
                </div>
            )}
        </>
    );
};

export default Table;
