import "./style.scss";

import { FC } from "react";
import Select from "../Select";

type DocumentTypeSelectProps = {
    handleChange: (value: string) => void;
    isVerification?: boolean;
    type: string;
};

const options = [
    {
        value: "Carte d'identité",
        label: "Carte d'identité",
    },
    {
        value: "Passeport",
        label: "Passeport",
    },
    {
        value: "Permis de conduire",
        label: "Permis de conduire",
    },
    {
        value: "Titre de séjour",
        label: "Titre de séjour",
    },
];

const DocumentTypeSelect: FC<DocumentTypeSelectProps> = ({ handleChange, isVerification, type }) => {
    return (
        <div className="DocumentTypeSelect">
            <h3>Type de document</h3>
            <p className="mb-1">
                {!isVerification &&
                    "Nous vous recommandons de choisir le type de document avec lequel vous voyagez le plus souvent ou que vous utilisez pour les formalités administratives."}
            </p>
            <Select
                options={options}
                value={type}
                placeholder="Choisir un type de document"
                handleChange={handleChange}
            />
        </div>
    );
};

export default DocumentTypeSelect;
