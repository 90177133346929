import "./style.scss";

import React, { FC } from "react";

import Loader from "../Loader";

export enum Button_Type {
  Error = "error",
  SUCCESS = "success",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  LABEL = "label",
  LINK = "link",
  WHITE = "white",
  LINK_EXTERNAL = "link-external",
  BASE = "base",
  REVERSE_ERROR = "reverse_error"
}

export enum Button_Style {
  SOLID = "solid",
  OUTLINED = "outlined",
};


export type ButtonProps = {
  label?: string;
  type?: Button_Type;
  buttonType?: "button" | "submit";
  size?: string;
  style?: Button_Style;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  className?: string;
  icon?: React.ReactNode;
  isIconButton?: boolean;
  isLoader?: boolean;
  iconHeight?: string;
} & Record<string, any>;

const Button: FC<ButtonProps> = ({
  label,
  size,
  style,
  type,
  buttonType,
  disabled,
  onClick,
  icon,
  className,
  isIconButton,
  isLoader,
  iconHeight,
  ...rest
}) => {
  const getIconColor = () => {
    if (style === "outlined" && disabled && type === "secondary") {
      return "#8B8EB0";
    }
    else if (disabled && type === "primary") {
      return "#9FC1EF";
    }
    else if (style === "outlined" && type === "error") {
      return "#FF5326";
    } else if (style === "outlined" && type === "success") {
      return "#17EB79";
    } else if (style === "outlined" && type === "primary") {
      return "#1956A8";
    } else if (style === "outlined" && type === "secondary") {
      return "#171C60";
    } else if (type === "label") {
      return "#1956A8";
    } else if (type === "white") {
      return "#1956A8";
    } else if (type === "reverse_error") {
      return "#FF5326";
    } else if (type === "base") {
      return "#1956A8"
    }
    return "#fff";
  };

  const iconProps = {
    fill: getIconColor(),
    width: size === "more" ? '8px' : '16px',
    height: iconHeight ? iconHeight : "16px",
  };

  const buttonClass = `btn ${style ? "btn-" + style : "btn-solid"} ${size ? "btn-" + size : "btn-L"
    } ${type ? "btn-" + type : "btn-primary"} ${className} ${isIconButton && "btn-icon"}`;

  return (
    <button className={buttonClass} type={buttonType} disabled={disabled} onClick={onClick} {...rest}>
      {label && label}
      {icon && !isLoader &&
        React.isValidElement(icon) &&
        React.cloneElement(icon, iconProps)}
      {isLoader && <Loader size="16px" />}
    </button>
  );
};

export default Button;
