import { FC, useState } from "react";

import Button from "../components/Button";
import { ID_TRACKER_PRICING } from "../constants/pricings";
import PageHeader from "../components/PageHeader";
import PriceCard from "../components/PriceCard";
import ProfilCard from "../components/ProfilCard/ProfilCard";
import logoIdTracker from "../assets/img/ID Tracker.svg";
import { useNavigate } from "react-router-dom";

const IDTrackerPricing: FC = () => {
    const navigate = useNavigate();
    const [isMonthActive, setIsMonthActive] = useState(true);

    const handleNext = (planIndex: number) => {
        if (planIndex === 0) {
            navigate(`/particuliers/profil`);
        } else {
            navigate(`/particuliers/payment/id-tracker/${planIndex}/${isMonthActive ? "month" : "year"}`);
        }
    };

    return (
        <div className="IDTrackerPricing">
            <PageHeader>
                <h4>ID TRACKER</h4>
            </PageHeader>
            <div className="content__body">
                <ProfilCard
                    title="Facturation"
                    logo={logoIdTracker}>
                    <h5>TROUVER le plan pour votre abonnement ID Tracker</h5>
                    <div className="row mb-1">
                        {ID_TRACKER_PRICING.map((plan, index) => (
                            <div className="col-sm-4">
                                <PriceCard
                                    plan={plan}
                                    title={`${plan?.title ? plan?.title : ""}`}
                                    logo={logoIdTracker}
                                    isMonthActive={isMonthActive}
                                    setIsMonthActive={setIsMonthActive}>
                                    <Button
                                        label="Souscrire"
                                        size="L"
                                        onClick={() => handleNext(index)}
                                    />
                                </PriceCard>
                            </div>
                        ))}
                    </div>
                </ProfilCard>
            </div>
        </div>
    );
};
export default IDTrackerPricing;
