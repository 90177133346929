import React, { useEffect, useState } from "react";

import Button from "../components/Button";
import PageHeader from "../components/PageHeader";
import TablePro from "../components/TablePro";
import { getVerifyHistoric } from "../api/analysis";
import { useNavigate } from "react-router-dom";
import { Button_Type } from '../components/Button/Button';

const DashboardPro: React.FC = () => {
  const [currentVerifications, setCurrentVerifications] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getUserVerifications();

  }, []);

  const getUserVerifications = async () => {
    let type = "partner"
    try {
      const request = await getVerifyHistoric(type);
      setCurrentVerifications(request.data.historiques);
    } catch (error: any) {
      console.log(error.response.data);
    }
  }
  const handleAddClick = () => {
    navigate("/professionnels/verifier-document");
  };
  const columns = [
    {
      key: 'idType',
      label: 'Type de document'
    },
    {
      key: 'documentNumber',
      label: 'Numéro'
    },
    {
      key: 'createdAt',
      label: 'Date de vérification'
    },
    {
      key: 'controls',
      label: 'Statut'
    },
    {
      key: 'validity',
      label: 'ID Tracker'
    },

  ]
  return <><PageHeader><h5>Vos vérifications</h5></PageHeader>
    <div className="content__body">
      <Button label="Vérifier un document" type={Button_Type.SECONDARY} onClick={() => handleAddClick()} className="mb-1" />
      {currentVerifications &&
        <TablePro
          columns={columns}
          rows={currentVerifications}
        />}
    </div>
  </>;
};

export default DashboardPro;
