import './style.scss';

import { FC } from 'react';

interface LoaderProps {
  text?: string;
  size?: string;
}
const Loader: FC<LoaderProps> = ({ text, size }) => {
  return (
    <div className='loader'>
      <svg className="spinner" width={size ? size : '42px'} height={size ? size : '42px'} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
      {text && <p> {text}</p>}
    </div>
  );
}

export default Loader;
