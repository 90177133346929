import "../assets/scss/style.scss";

import Button, { Button_Style, Button_Type } from "../components/Button/Button";
import { FC, useContext, useEffect, useState } from "react";

import Alert from "../components/Alert";
import BuyCreditsCard from "../components/BuyCreditsCard";
import DeleteAccountModal from "../components/DeleteAccountModal";
import { ReactComponent as IconDelete } from "../assets/img/icons/icon_Trash.svg";
import { ReactComponent as IconVerified } from "../assets/img/icons/icon_status-Verified.svg";
import { ReactComponent as IconWarning } from "../assets/img/icons/icon_status-Warning.svg";
import PageHeader from "../components/PageHeader";
import PriceCard from "../components/PriceCard/PriceCard";
import ProfilCard from "../components/ProfilCard/ProfilCard";
import ResetPassword from "../components/ResetPassword";
import UploadProfilePicture from "../components/UploadProfilePicture";
import { UserContext } from "../contexts/UserContext";
import { getSubscription } from "../api/user";
import logoIdTracker from "../assets/img/ID Tracker.svg";
import logoProfil from "../assets/img/Accompagnement.svg";
import logosecurity from "../assets/img/security.svg";
import { useNavigate } from "react-router-dom";

const Profil: FC = () => {
    const navigate = useNavigate();
    const { user, userType, isVerified } = useContext(UserContext);

    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const subjects: any = {
        modify: {
            text: "Modification de mon compte IDProtect",
            body: "Bonjour, Je souhaite modifier les informations de mon compte ID Protect.",
        },
        cancel: {
            text: "Résiliation de mon abonnement IDProtect",
            body: "Bonjour, Je souhaite résilier mon abonnement.",
        },
        buyCredits: {
            text: "Achat de crédits de vérification IDProtect",
            body: "Bonjour, Je souhaite acheter des crédits de vérification.",
        },
    };

    const userInfos = `\n-----------\n ${user?.firstname} ${user?.lastname} \n ${user?.email} \n ${user?.phoneNumber}`;
    const mailTo = (subject: string) => {
        const mail = "contact@idprotect.fr";
        window.location.href = `mailto:${mail}?subject=${subjects[subject].text}&body=${
            subjects[subject].body + encodeURIComponent(userInfos)
        }`;
    };

    const [offer, setOffer] = useState<any>();

    const type = user?.type;

    useEffect(() => {
        const getOffer = async () => {
            try {
                const response = await getSubscription(type, user?.email);
                setOffer(response.data.offrePurchase);
            } catch (error) {
                console.log(error);
            }
        };

        if (user) {
            getOffer();
        }
    }, [user, type]);

    return (
        <div className="profil">
            <PageHeader>
                <h5>Mon profil</h5>
            </PageHeader>
            <div className="content__body">
                <div className="row mb-1">
                    <div className="col-sm-7">
                        <ProfilCard
                            title="profil"
                            logo={logoProfil}>
                            <div className="d-flex gap-2 flex-wrap">
                                <UploadProfilePicture />
                                <div>
                                    {user && (
                                        <h3 className="mb-1 ProfilCard__title">
                                            {user?.firstname + " " + user?.lastname + " "}
                                            {isVerified ? (
                                                <span className={`isVerified tag tag-icon tag-success ml-2`}>
                                                    <IconVerified
                                                        fill="#17EB79"
                                                        height={16}
                                                        width={16}
                                                    />{" "}
                                                    Compte vérifié
                                                </span>
                                            ) : (
                                                <span className={`isVerified tag tag-icon tag-error ml-2`}>
                                                    <IconWarning
                                                        fill="#FF5326"
                                                        height={16}
                                                        width={16}
                                                    />{" "}
                                                    Compte non vérifié
                                                </span>
                                            )}
                                        </h3>
                                    )}
                                    {user?.email && <p className="mb-1">{user?.email}</p>}
                                    {user?.phoneNumber && <p className="mb-1">{user?.phoneNumber}</p>}
                                    <Alert type="info">
                                        Pour modifier vos informations:{" "}
                                        <span
                                            className="pointer underline"
                                            onClick={() => mailTo("modify")}>
                                            contact@idprotect.fr
                                        </span>{" "}
                                    </Alert>
                                    <Button
                                        type={Button_Type.Error}
                                        style={Button_Style.OUTLINED}
                                        label="Supprimer mon compte"
                                        icon={<IconDelete />}
                                        onClick={() => setIsModalDeleteOpen(!isModalDeleteOpen)}
                                    />
                                </div>
                            </div>

                            {/* <Button type={userType === "partner" ? 'secondary' : 'primary'} label="Nous contacter" /> */}
                        </ProfilCard>
                    </div>
                    <div className="col-sm-5">
                        <ProfilCard
                            title="Sécurité"
                            logo={logosecurity}>
                            <h4>Réinitialiser son mot de passe</h4>
                            <ResetPassword />
                        </ProfilCard>
                    </div>
                </div>
                <ProfilCard
                    title="Votre abonnement"
                    logo={logoIdTracker}>
                    <div className="row mb-1">
                        <div className="col-sm-7">
                            <PriceCard
                                monthly
                                title={`Id Tracker ${offer?.name ? ": " + offer?.name : ""}`}
                                logo={logoIdTracker}
                                price={`${offer?.price ? offer?.price : "0"}€`}
                                subtitle="Restez informé quand votre document est présenté">
                                <p>
                                    Prévenez l’utilisation frauduleuse de vos documents d’identité en vous opposant à leur utilisation en
                                    temps réel.
                                </p>
                                <div className="d-flex gap-1 ProfilCard__buttons">
                                    <a
                                        // href="https://idprotect.fr/id-tracker/#tarifblock_46c377ecc219640933d8cef19ec0343b"
                                        className={`btn ${userType === "partner" ? " btn-secondary" : "btn-primary"} btn-solid`}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() => navigate(`/particuliers/id-tracker-pricing`)}>
                                        Changer d'abonnement
                                    </a>
                                    <Button
                                        // eslint-disable-next-line react/style-prop-object
                                        style={Button_Style.OUTLINED}
                                        type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}
                                        label="Résilier"
                                        onClick={() => mailTo("cancel")}
                                    />
                                </div>
                            </PriceCard>
                        </div>
                        <div className="col-sm-5">
                            <BuyCreditsCard />
                        </div>
                    </div>
                </ProfilCard>
            </div>

            {type && (
                <DeleteAccountModal
                    isOpen={isModalDeleteOpen}
                    onClose={() => setIsModalDeleteOpen(false)}
                />
            )}
        </div>
    );
};
export default Profil;
