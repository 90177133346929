import './style.scss';

import { FC, useContext, useState } from 'react';

import Alert from '../Alert';
import Input from '../Input';
import Loader from '../Loader';
import Modal from '../Modal/Modal';
import { UserContext } from "../../contexts/UserContext";
import { shareFile } from '../../api/invitation';

interface ModalSendFileProps {
  isOpen: boolean;
  onClose: () => void;
  file: File | null;
}
const ModalSendFile: FC<ModalSendFileProps> = ({ isOpen, onClose, file }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [email, setEmail] = useState<string>("");
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const type = user?.type;
  const sendFile = async () => {
    setIsLoading(true);
    try {
      const response = await shareFile(email, file, type);
      setIsLoading(false);
      setSuccessMessage("Le document a bien été envoyé")

    } catch (error: any) {
      setIsLoading(false);
      setErrorMessage(error.response.data.message);
    }
  }
  return (
    <>{isOpen && (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Envoyez le document"
        labelOnConfirm='Envoyer'
        onConfirm={sendFile}
        confirmButtonDisabled={!email}
      >
        <p>Renseignez l'email de la personne ou organisme à qui vous voulez envoyer ce document.</p>
        {errorMessage && <Alert type="error">{errorMessage}</Alert>}
        {successMessage && <Alert type="success">{successMessage}</Alert>}
        <Input placeholder='email' type="email" onChange={(e) => setEmail(e.target.value)} value={email} />
        {isLoading && <Loader />}
      </Modal>
    )}</>
  );
}

export default ModalSendFile;
