import './style.scss';

import DocumentCard from '../DocumentCard';
import { DocumentUnion } from '../../types/types';
import { FC } from 'react';
import NotificationsList from '../NotificationsList';
import SelectedRowsActions from '../SelectedRowsActions';
import Table from '../Table';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const columns = [
  { key: 'nationality', label: '' },
  { key: 'type', label: 'Type de document' },
  { key: 'number', label: 'Numéro' },
  { key: 'createdAt', label: 'Date de l\'ajout' },
  { key: 'status', label: 'Statut' },
  { key: 'isBlocked', label: '' },
];
const TabContent: FC<{
  documents: DocumentUnion[],
  selectedRows: DocumentUnion[],
  onSelectionChange: (rows: DocumentUnion[]) => void,
}> = ({ documents, selectedRows, onSelectionChange }) => {
  return (
    <>
      <SelectedRowsActions
        isSelected={selectedRows.length > 0}
        documents={selectedRows}
        onSelectionChange={onSelectionChange}
      />
      <div className="row">
        <div className="col-lg-9">
          {isMobile ? (
            <>
              {documents.map((document: DocumentUnion) => (
                <DocumentCard document={document} />
              ))}
            </>
          ) : (
            <Table
              columns={columns}
              rows={documents}
              onSelectionChange={onSelectionChange}
              selectedRows={selectedRows}
              selectable
            />
          )}
        </div>
        <div className="col-lg-3">
          <NotificationsList />
        </div>
      </div>
    </>
  );
};

export default TabContent;
