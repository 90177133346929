import "./style.scss";

import { FC } from "react";
import MenuDesktop from "../MenuDesktop";
import MenuMobile from "../MenuMobile";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const Aside: FC = () => {
    return <>{isMobile ? <MenuMobile /> : <MenuDesktop />}</>;
};

export default Aside;
