import "./style.scss";

import { useCallback, useEffect, useRef, useState } from "react";

import File from "../File";
import HeaderSection from "../HeaderSection";
import { ReactComponent as IconLeft } from "../../assets/img/icons/icon_arrow-Left.svg";
import { ReactComponent as IconRight } from "../../assets/img/icons/icon_arrow-Right.svg";

const DocsPerOrganisms = ({ organisms }: { organisms: any }) => {
    const [isScrollableLeft, setIsScrollableLeft] = useState<boolean>(false);
    const [isScrollableRight, setIsScrollableRight] = useState<boolean>(true);

    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const checkScrollButtons = useCallback(() => {
        const container = scrollContainerRef.current;
        if (container) {
            const maxScrollLeft = container.scrollWidth - container.clientWidth;
            setIsScrollableLeft(container.scrollLeft > 0);
            setIsScrollableRight(container.scrollLeft < maxScrollLeft || container.scrollLeft === maxScrollLeft - 0.5);
        }
    }, []);

    const scrollLeft = useCallback(() => {
        if (scrollContainerRef.current) {
            (scrollContainerRef.current as HTMLElement).scrollBy({ top: 0, left: -300, behavior: "smooth" });
        }
    }, []);

    const scrollRight = useCallback(() => {
        if (scrollContainerRef.current) {
            (scrollContainerRef.current as HTMLElement).scrollBy({ top: 0, left: 300, behavior: "smooth" });
        }
    }, []);

    useEffect(() => {
        const container = scrollContainerRef.current;
        if (container) {
            container.addEventListener("scroll", checkScrollButtons);

            // Vérification initiale pour les boutons de défilement
            checkScrollButtons();
        }

        return () => {
            if (container) {
                container.removeEventListener("scroll", checkScrollButtons);
            }
        };
    }, []);

    return (
        <>
            <HeaderSection
                title="Organismes contactés"
                number="5/5"
            />
            <div
                className="DocsPerOrganisms"
                ref={scrollContainerRef}>
                <div className="DocsPerOrganisms__list scroll-container">
                    {organisms?.map((organism: any, index: number) => (
                        <div
                            className="DocsPerOrganisms__organism"
                            key={index}>
                            <div className="DocsPerOrganisms__organism__header">
                                <div className="DocsPerOrganisms__organism__header__img">
                                    {/* <img
                                        src={organism.logoPath}
                                        alt={organism.name}
                                    /> */}
                                </div>
                                <h4 className="DocsPerOrganisms__organism__header__title">{organism.name}</h4>
                            </div>
                            <div className="DocsPerOrganisms__organism__documents">
                                <File
                                    file={{
                                        name: organism.declarationName ? organism.declarationName : "Déclaration",
                                        path: organism.declaration,
                                        status: !organism.declaration ? "En attente" : "Envoyé",
                                    }}
                                />
                                <File
                                    file={{
                                        name: organism.accuseName ? organism.accuseName : "Accusé de réception",
                                        path: organism.accuse,
                                        status: !organism.accuse ? "En attente" : "Reçu",
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="scroll-buttons">
                <IconLeft
                    onClick={scrollLeft}
                    fill={isScrollableLeft ? "#1956A8" : "#9FC1EF"}
                    className="pointer"
                />
                <IconRight
                    onClick={scrollRight}
                    fill={isScrollableRight ? "#1956A8" : "#9FC1EF"}
                    className="pointer"
                />
            </div>
        </>
    );
};

export default DocsPerOrganisms;
