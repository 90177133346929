import "./style.scss";

import Button, { Button_Style, Button_Type } from "../Button/Button";
import { FC, useContext } from "react";

import { DocumentBasylic } from "../../types/types";
import Loader from "../Loader";
import ShareID from "@shareid/onboarding_desktop";
import { UserContext } from "../../contexts/UserContext";
import { getVerificationResult } from "../../api/analysis";

type FailDataType = {
    message: string;
    external_id: string;
    code: number;
    description: string[];
};
type ShareIDVerificationProps = {
    accessToken: string;
    setIsScanning?: any;
    setIsScanned?: any;
    setErrorType?: any;
    setDocument?: any;
    errorType?: any;
    setIsShareID: any;
};

const ShareIDVerification: FC<ShareIDVerificationProps> = ({
    accessToken,
    setIsScanning,
    setIsScanned,
    setErrorType,
    setDocument,
    errorType,
    setIsShareID,
}) => {
    const { user } = useContext(UserContext);
    const userType = user?.type;
    const formatDocument = (document: any) => {
        const { doc_num, surname, name, sex, expiration_date, issuance_date, mrz_1, mrz_2, birth_date } = document.document.ocr;
        const documentFormatted: DocumentBasylic = {
            id: document.id,
            numeroDocument: doc_num,
            nom: name,
            prenom: surname,
            nomUsage: surname,
            dateNaissance: birth_date,
            paysNaissance: document.document.type.country_code,
            sexe: sex,
            typeDocument: document.document.type.document_type,
            dateExpiration: expiration_date,
            dateDelivrance: issuance_date,
            conformite: true,
            darkWeb: false,
            mrz: mrz_1,
            viz: mrz_2,
        };
        return documentFormatted;
    };

    const getDataFromUID = async (uuid: string) => {
        setIsScanning && setIsScanning(true);

        try {
            const response = await getVerificationResult(userType, uuid);
            if (response.data.documentAnalyse.result === "rejected") {
                setIsScanned && setIsScanned(true);
                setIsScanning && setIsScanning(false);
                setErrorType && setErrorType({ status: 400, message: "Suspection de fraude" });
            } else if (response.status === 200) {
                setIsScanned && setIsScanned(true);
                setDocument && setDocument(formatDocument(response.data.documentAnalyse));
                setIsScanning && setIsScanning(false);
            }
        } catch (error: any) {
            console.error("Une erreur s'est produite :", error);
            if (error.response.status === 404 && errorType?.status !== 400) {
                setTimeout(() => getDataFromUID(uuid), 5000);
            }
        }
    };
    return (
        <div className="videoVerification">
            {accessToken ? (
                <ShareID
                    environment="production"
                    debug={true}
                    accessToken={accessToken}
                    metadata={{
                        external_id: user?.id + "_" + new Date().getTime().toString(),
                    }}
                    language="fr"
                    onFail={(data: FailDataType) => {
                        console.log("fail", data);
                    }}
                    onSuccess={(data: any) => {
                        getDataFromUID(data.uuid);
                    }}
                    onExit={(data: any) => console.log("exit", data)}
                    theme={{
                        colors: {
                            primary: {
                                active: "#1956A8",
                                hover: "#0F3465",
                                disabled: "#9FC1EF",
                            },
                        },
                    }}
                />
            ) : (
                <Loader />
            )}
            <div className="">
                <p className="mb-2 text-center">Vous n'avez pas le document d'identité sur vous ?</p>
                <Button
                    type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}
                    style={Button_Style.OUTLINED}
                    label="Utiliser un scan du document"
                    onClick={() => setIsShareID(false)}
                    className="m-auto"
                />
            </div>
        </div>
    );
};

export default ShareIDVerification;
