import "./style.scss";

import { FC, useEffect, useState } from "react";

import MenuItems from "../MenuItems";
import MenuProfile from "../MenuProfile";
import logo from "../../assets/img/logo_ID-Protect-blanc.png";
import { useLocation } from "react-router-dom";

const MenuMobile: FC = () => {
    const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setMobileMenuIsOpen(false);
    }, [location.pathname]);

    return (
        <div className="aside aside--mobile">
            <input
                id="burger"
                type="checkbox"
                onClick={() => setMobileMenuIsOpen(!mobileMenuIsOpen)}
                checked={mobileMenuIsOpen}
            />
            <label htmlFor="burger">
                <span></span>
                <span></span>
                <span></span>
            </label>
            <img
                className="navbar-brand"
                src={logo}
                alt="ID Protect"
            />
            {mobileMenuIsOpen && (
                <div className="nav">
                    <MenuItems isCollapsed={false} />
                    <MenuProfile isCollapsed={false} />
                </div>
            )}
        </div>
    );
};

export default MenuMobile;
