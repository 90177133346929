import axios from "../../axiosConfig";

export async function createAccompaniment(type) {
	const body = new FormData();
	body.append("type", type);
    try {
        const response = await axios.post("/client/accompaniment", body);
        return response;
    } catch (error) {
        return error;
    }
}

export async function getAccompaniment(type) {
    const body = new FormData();
    body.append("type", type);
    try {
        const response = await axios.post("/client/accompaniment/list", body);
        return response.data;
    } catch (error) {
        return error;
    }
}

export async function addDocumentToAccompaniment(type, files) {
    try {
        const data = new FormData();
        data.append("type", type);
        data.append("filesToUpload", new File([files], files.name));
        const response = await axios.put("/client/accompaniment/document", data);
        if (response) {
            return response.data;
        }
    } catch (error) {
        console.error(error);
    }
}

export async function removeDocumentFromAccompaniment(type, id) {
    try {
        const response = await axios.post(`/client/accompaniment/document/${id}`, { type });
        return response;
    } catch (error) {
        return error;
    }
}
