import { FolderType, PartnerType } from '../../types/types';
import PartnersCard from '../PartnersCard';
import './style.scss';

import { FC } from 'react';

type PartnersListProps = {
    partners: Array<PartnerType>
    folder: FolderType | undefined;
}


const PartnersList: FC<PartnersListProps> = ({ partners, folder }) => {
    return (
        <>
            <div className="list_partners">
                {partners.map((partner) =>
                    <div key={partner.id} >
                        <PartnersCard partner={partner} folder={folder}></PartnersCard>
                    </div>
                )}
            </div>
        </>
    );
}

export default PartnersList;
