import "./style.scss";
import "react-toggle/style.css";

import { FC, useContext } from "react";

import TableRow from "../TableRow";
import { UserContext } from "../../contexts/UserContext";

const FoldersList: FC<{
    folders: any[];
}> = ({ folders }) => {
    const { user } = useContext(UserContext);
    const type = user?.type;

    return (
        <table className="FoldersList">
            <thead>
                <tr>
                    <th className="w-40">Nom</th>
                    <th>Partagé avec</th>
                    <th>Secret</th>
                </tr>
            </thead>
            <tbody>
                {folders.map((folder, index) => (
                    <TableRow
                        key={index}
                        folder={folder}
                        type={type}></TableRow>
                ))}
            </tbody>
        </table>
    );
};

export default FoldersList;
