import './style.scss';


import { FC } from 'react';

type SkeletonProps = {
  variant?: 'circular' | 'rectangular' | 'rounded' | 'text' | string;
  width?: string | number;
  height?: string | number;
};

const Skeleton: FC<SkeletonProps> = ({ variant = 'rectangular', width, height }) => {
  const skeletonClass = `skeleton ${variant}`;

  const style = {
    width: width,
    height: height,
  };

  return <div className={skeletonClass} style={style}></div>;
};

export default Skeleton;
