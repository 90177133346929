import "./style.scss";

import { FC } from "react";
import { ReactComponent as IconComment } from "../../assets/img/icons/icon_Chat.svg";
import { ReactComponent as IconDocument } from "../../assets/img/icon_CNI.svg";
import { ReactComponent as IconFile } from "../../assets/img/icons/icon_File.svg";
import { ReactComponent as IconPay } from "../../assets/img/icons/payment/payStep.svg";
import { ReactComponent as IconUser } from "../../assets/img/icons/icon_User.svg";

interface IDAssistantStepperMenuProps {
    steps: { isCurrent: boolean; isDone: boolean }[];
    setSteps: (steps: { isCurrent: boolean; isDone: boolean }[]) => void;
    handleUpdateStep: (step: number) => void;
}

const IDAssistantStepperMenu: FC<IDAssistantStepperMenuProps> = ({ steps, setSteps, handleUpdateStep }) => {
    return (
        <div className="IDAssistantStepperMenu d-flex gap-2 flex-column align-items-center">
            <IconDocument
                className="IDAssistantStepperMenu__icon"
                fill={steps[0].isDone ? "#17EB79" : steps[0].isCurrent ? "#1956A8" : "#9FC1EF"}
                onClick={() => handleUpdateStep(0)}
            />
            <IconFile
                className="IDAssistantStepperMenu__icon"
                fill={steps[1].isDone ? "#17EB79" : steps[1].isCurrent ? "#1956A8" : "#9FC1EF"}
                onClick={() => steps[1].isDone && handleUpdateStep(1)}
            />
            <IconUser
                className="IDAssistantStepperMenu__icon"
                fill={steps[2].isDone ? "#17EB79" : steps[2].isCurrent ? "#1956A8" : "#9FC1EF"}
                onClick={() => steps[0].isDone && handleUpdateStep(2)}
            />
            <IconComment
                className="IDAssistantStepperMenu__icon"
                fill={steps[3].isDone ? "#17EB79" : steps[3].isCurrent ? "#1956A8" : "#9FC1EF"}
                onClick={() => steps[2].isDone && handleUpdateStep(3)}
            />
            <IconPay
                className="IDAssistantStepperMenu__icon"
                fill={
                    steps[4].isDone || steps[5].isDone || steps[6].isDone
                        ? "#17EB79"
                        : steps[4].isCurrent || steps[5].isCurrent || steps[6].isCurrent
                        ? "#1956A8"
                        : "#9FC1EF"
                }
                onClick={() => steps[3].isDone && handleUpdateStep(4)}
            />
        </div>
    );
};

export default IDAssistantStepperMenu;
