import * as Yup from "yup";

import { Button_Style, Button_Type } from "../components/Button/Button";
import { FC, useState } from "react";

import Alert from "../components/Alert";
import Button from "../components/Button";
import Input from "../components/Input";
import Loader from "../components/Loader";
import { resend } from "../api/user";
import svgImage from "../assets/img/Illustration_documents.svg";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

const ForgetPassword: FC = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const [isSent, setIsSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        email: Yup.string().email("Format d'email invalide.").required("Ce champ est requis"),
    });

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema,
        onSubmit: async (values) => {
            setIsLoading(true);
            try {
                const response = await resend(values.email, "p");
                setIsSent(true);
                setIsLoading(false);
            } catch (error: any) {
                setErrorMessage(error.response.data.error);
                setIsLoading(false);
            }
        },
    });

    return (
        <>
            <main className="login">
                <div className="login__container">
                    <div className="login__form">
                        <h5>Espace Particulier</h5>
                        <h2>Mot de passe oublié</h2>
                        {errorMessage && <Alert type="error">{errorMessage}</Alert>}
                        {isSent ? (
                            <>
                                <Alert type="success">Un email vous a été envoyé pour réinitialiser votre mot de passe.</Alert>
                                <Button
                                    label="Renvoyer"
                                    type={Button_Type.PRIMARY}
                                    className="m-auto"
                                    onClick={() => setIsSent(false)}
                                />
                            </>
                        ) : (
                            <form onSubmit={formik.handleSubmit}>
                                <p className="mb-1">Entrez votre adresse e-mail pour réinitialiser votre mot de passe</p>
                                <Input
                                    placeholder="Email"
                                    name="email"
                                    type="email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    errorMessage={formik.errors.email}
                                />
                                {isLoading && <Loader />}
                                <div className="mt-auto">
                                    <Button
                                        label="Envoyer"
                                        type={Button_Type.PRIMARY}
                                        className="m-auto mb-1"
                                        disabled={!formik.isValid || !formik.values.email}
                                    />
                                    <Button
                                        label="Connexion"
                                        type={Button_Type.PRIMARY}
                                        style={Button_Style.OUTLINED}
                                        className="m-auto mb-0"
                                        onClick={() => navigate("/particuliers/login")}
                                    />
                                </div>
                            </form>
                        )}
                    </div>
                    <div className="login__img">
                        <img
                            src={svgImage}
                            className="illustration-document"
                            alt="Login"
                        />
                    </div>
                </div>
            </main>
        </>
    );
};

export default ForgetPassword;
