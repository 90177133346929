import "./style.scss";

import React, { FC } from "react";

import { ASSISTANCE_PRICING } from "../../constants/pricings";
import Button from "../Button";
import { Button_Style } from "../Button/Button";
import RadioButtonPricing from "../RadioButtonPricing";

interface IDAssistantPricingProps {
    steps: any;
    setDatas: (data: any) => void;
    datas: any;
    handlePreviousStep: () => void;
    handleNextStep: () => void;
}

const IDAssistantPricing: FC<IDAssistantPricingProps> = ({ setDatas, datas, steps, handlePreviousStep, handleNextStep }) => {
    return (
        <div className="IDAssistantPricing w-100 h-100 d-flex flex-column align-items-center justify-content-between">
            <div className="w-100 text-center">
                <h3>Trouvez le plan pour votre accompagnement</h3>
            </div>
            <div className="w-100">
                {ASSISTANCE_PRICING.map((plan, i) => (
                    <div key={i}>
                        {plan.title.toLowerCase() === "standard" && <div className="recommandee">Recommandée</div>}
                        <RadioButtonPricing
                            key={i}
                            plan={plan}
                            setDatas={setDatas}
                            datas={datas}
                        />
                        <br />
                    </div>
                ))}
            </div>
            <div className={`d-flex justify-content-${!steps[0].isCurrent ? "between" : "end"} w-100`}>
                {!steps[0].isCurrent && (
                    <Button
                        label={"Retour"}
                        onClick={() => handlePreviousStep()}
                        style={Button_Style.OUTLINED}
                    />
                )}
                <Button
                    disabled={false}
                    label={"Suivant"}
                    onClick={() => handleNextStep()}
                    data-testid="plan-next-button"
                />
            </div>
        </div>
    );
};

export default IDAssistantPricing;
