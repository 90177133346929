import { useContext, useState } from "react";

import Alert from "../Alert";
import { DocumentUnion } from "../../types/types";
import { DocumentsContext } from "../../contexts/DocumentsContext";
import Input from "../Input";
import Modal from "../Modal";
import { UserContext } from "../../contexts/UserContext";
import { checkPassword } from "../../api/auth";
import { updateStateDocument } from "../../api/followed";

interface LockDocumentModalProps {
  isOpen: boolean;
  onClose: () => void;
  documents: DocumentUnion[];
  documentsLocked: boolean;
  onSubmit?: (selectedRows: DocumentUnion[]) => void;
}

const LockDocumentModal: React.FC<LockDocumentModalProps> = ({
  isOpen,
  onClose,
  documents,
  documentsLocked,
  onSubmit
}) => {
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const { refreshDocuments } = useContext(DocumentsContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { user } = useContext(UserContext);
  const type = user?.type;

  const handlePasswordChange = async () => {
    const response = await checkPassword(user?.id, password, type);
    return response.data;
  };

  const handleClose = () => {
    onClose();
    setPassword("");
    setErrorMessage("");
    setSuccessMessage("");
    setIsPasswordValid(true);
  }

  const handleLockUnlock = async () => {
    try {
      await handlePasswordChange();
      if (isPasswordValid) {
        try {
          await updateStateDocument(type, documents);
          onSubmit && onSubmit([]);
          setSuccessMessage(
            `Document(s) ${documentsLocked ? "déverrouillé(s)" : "verrouillé()s"
            } avec succès.`
          );
          setTimeout(() => {
            handleClose();
          }, 1500);
        } catch (error: any) {
          if (error.response?.status === 500) {
            setErrorMessage("Erreur de serveur interne");
          } else {
            setErrorMessage("Une erreur est survenue");
          }
        } finally {
          refreshDocuments();
        }
      } else {
        setErrorMessage("Mot de passe invalide");
      }
    } catch (error: any) {
      setIsPasswordValid(false);
      setErrorMessage(error.response.data.message);
    }
  };


  return (
    <>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={handleClose}
          title={`${!documentsLocked ? "Verrouiller" : "Déverrouiller"} ${documents.length > 1 ? "vos documents" : "votre document"}`}
          labelOnConfirm={!documentsLocked ? "Verrouiller" : "Déverrouiller"}
          onConfirm={handleLockUnlock}
          confirmButtonDisabled={!isPasswordValid || !password}
        >
          {(errorMessage && isPasswordValid) && <Alert type="error"  > {errorMessage} </Alert>}
          {successMessage && <Alert type="success"> {successMessage} </Alert>}
          <p className="mb-1">
            Confirmez votre mot de passe pour
            {documentsLocked ? " déverrouiller " : " verrouiller "}
            {documents.length > 1
              ? `les ${documents.length} documents`
              : "le document"}
            {!documentsLocked &&
              ` et les ${documents.length > 1 ? "protéger" : "protéger de toute utilisation frauduleuse"}`}
            .
          </p>
          <Input
            placeholder="Mot de passe"
            type="password"
            value={password}
            onChange={(e) => { setPassword(e.target.value); setIsPasswordValid(true); setErrorMessage(""); }}
            errorMessage={!isPasswordValid && password ? "Mot de passe incorrect" : ""}
            className={!isPasswordValid && password ? "error" : ""}
          />
        </Modal>
      )}
    </>
  );
};

export default LockDocumentModal;
