import axios from "axios";

const apiInstance = axios.create({
    withCredentials: true,
    baseURL: "https://api.idprotect.fr/",
    //baseURL: "https://api.staging.idprotect.fr/api",
});

// Ajouter un intercepteur de réponse pour gérer les erreurs globalement
apiInstance.interceptors.response.use(
    (response) => {
        // Retourner la réponse si tout va bien

        return response;
    },
    (error) => {
        // Vous pouvez insérer ici la logique de gestion des erreurs
        if (error.response) {
            console.error(error.response.data);
        } else if (error.request) {
            console.error("Pas de réponse reçue:", error.request);
        } else {
            console.error("Erreur lors de la configuration de la requête:", error.message);
        }

        // Rejeter l'erreur pour que vous puissiez la récupérer dans les composants qui utilisent cette instance Axios
        return Promise.reject(error);
    },
);

export default apiInstance;
