import './style.scss';

import React, { FC, useState } from 'react';

const NotFound: FC = () => {
  return (
    <div className="NotFound">
      <div>
        <h1>404</h1>
        <p>Page non trouvée</p>
      </div>
    </div>
  );
}

export default NotFound;
