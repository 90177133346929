import NotFound from '../components/NotFound';
import React from 'react';

const NotFoundPage: React.FC = () => {
	return (
		<NotFound />
	);
};

export default NotFoundPage;
