import "./style.scss";

import { FC, useContext, useState } from "react";

import Button from "../Button";
import { Button_Type } from "../Button/Button";
import { ReactComponent as IconChevronLeft } from "../../assets/img/icons/icon_carret-Left.svg";
import { ReactComponent as IconChevronRight } from "../../assets/img/icons/icon_carret-Right.svg";
import MenuItems from "../MenuItems";
import MenuProfile from "../MenuProfile";
import { UserContext } from "../../contexts/UserContext";
import logo from "../../assets/img/logo_ID-Protect-blanc.png";
import logoIcon from "../../assets/img/logo_ID-Protect-blanc-icon.png";

const MenuDesktop: FC = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { userType } = useContext(UserContext);

    return (
        <div className={`aside ${isCollapsed ? "collapsed" : ""}`}>
            <div>
                <div className="aside__header">
                    <a
                        href="/"
                        className="navbar-brand">
                        <img
                            src={isCollapsed ? logoIcon : logo}
                            alt="ID Protect"
                        />
                    </a>
                    <Button
                        isIconButton
                        type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}
                        icon={isCollapsed ? <IconChevronRight fill="#fff" /> : <IconChevronLeft fill="#fff" />}
                        onClick={() => setIsCollapsed(!isCollapsed)}
                    />
                </div>
                <MenuItems isCollapsed={isCollapsed} />
            </div>
            <MenuProfile isCollapsed={isCollapsed} />
        </div>
    );
};

export default MenuDesktop;
