import './style.scss';

import { FC } from 'react';

type NationalityBadgeProps = {
  nationality?: string;
}
const NationalityBadge: FC<NationalityBadgeProps> = ({ nationality }) => {
  return (
    <div className="NationalityBadge">
      {nationality ?
        <>
          <img src={`${process.env.PUBLIC_URL}/img/flags/${nationality}.png`} alt={`drapeau ${nationality}`} className="flag" />
          {nationality}
        </>
        :
        <>
          <img src={`${process.env.PUBLIC_URL}/img/flags/fr.png`} alt={`drapeau FR`} className="flag" />
          FR
        </>
      }
    </div>
  );
}

export default NationalityBadge;
