import { DocumentBasylic, ErrorType } from "../types/types";
import { FC, useContext, useEffect, useState } from "react";

import AddDocumentForm from "../components/AddDocumentForm";
import Alert from "../components/Alert";
import BuyCreditsCard from "../components/BuyCreditsCard";
import DocumentIllustration from "../components/DocumentIllustration";
import ErrorScan from "../components/ErrorScan";
import Loader from "../components/Loader";
import { UserContext } from "../contexts/UserContext";
import VerifyResult from "../components/VerifyResult/VerifyResult";
import { useNavigate } from "react-router-dom";

interface VerifyProps {
    isDarkWeb?: boolean;
}
const Verify: FC<VerifyProps> = ({ isDarkWeb }) => {
    const { user } = useContext(UserContext);
    const credits = user?.credits;
    const [type, setType] = useState<string>("");
    const [isScanning, setIsScanning] = useState<boolean>(false);
    const [isScanned, setIsScanned] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorType, setErrorType] = useState<ErrorType>({ status: 0, message: "" });
    const [verifyReportURL, setVerifyReportURL] = useState<string>("");
    const [document, setDocument] = useState<DocumentBasylic>();
    const [showResult, setShowResult] = useState<boolean>(false);
    const navigate = useNavigate();
    const handleTypeChange = (type: string) => {
        setType(type);
    };
    const refreshPage = () => {
        setIsScanned(false);
        setShowResult(false);
        setErrorType({ status: 0, message: "" });
    };

    useEffect(() => {
        refreshPage();
    }, [navigate]);

    return (
        <div className="VerifyPage content__body h-100">
            <div className="VerifyPage__row row h-100">
                <div className="VerifyPage__content col-sm-6">
                    {credits === 0 ? (
                        <div className="VerifyResult card">
                            <Alert type="error">Vous n'avez pas de crédits pour vérifier un document. Veuillez acheter des crédits.</Alert>
                            <BuyCreditsCard />
                        </div>
                    ) : !isScanning && !isScanned && errorType?.status !== 400 && !showResult ? (
                        <AddDocumentForm
                            onChangeType={handleTypeChange}
                            type={type}
                            setIsScanning={setIsScanning}
                            setIsScanned={setIsScanned}
                            setErrorMessage={setErrorMessage}
                            setDocument={setDocument}
                            isVerification
                            errorType={errorType}
                            setErrorType={setErrorType}
                            setVerifyReportURL={setVerifyReportURL}
                            isDarkWeb={isDarkWeb}
                        />
                    ) : errorType?.status !== 400 ? (
                        <VerifyResult
                            isScanning={isScanning}
                            documentVerified={document}
                            refresh={() => refreshPage()}
                            verifyReportURL={verifyReportURL}
                            errorMessage={errorMessage}
                            isDarkWeb={isDarkWeb}
                        />
                    ) : (
                        <ErrorScan
                            error={errorType?.message}
                            verifyReportURL={verifyReportURL}
                            resetState={refreshPage}
                        />
                    )}
                </div>
                <div className="VerifyPage__illu col-sm-6 center">
                    <div>
                        <h1 className="VerifyPage__title text-center mb-4">
                            {isDarkWeb ? "Votre document d'identité est-il sur le dark web ?" : "Vérifiez l'authenticité d'un document"}
                        </h1>
                        <DocumentIllustration type={type} />
                        {isScanning && <Loader text="Analyse en cours" />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Verify;
