import './style.scss';

import { FC } from 'react';
import FileUploader from '../FileUploader';

type AddDocumentFormProps = {
  onDelete: (file: File) => void;
  onChangeFiles: (files: File[]) => void;
  title?: string;

};
const AddFilterDocument: FC<AddDocumentFormProps> = ({
  onChangeFiles, onDelete, title }) => {

  return (
    <>
      <h4>{title && title}</h4>
      <FileUploader
        onChange={onChangeFiles}
        onDelete={onDelete}
        title='Vos documents à protéger'
        maxFileSize={12}
        allowedFormats={'image/jpeg, image/png, application/pdf'}
      />
    </>
  );
}

export default AddFilterDocument;
