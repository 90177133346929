import './style.scss';

import { Button_Style, Button_Type } from '../Button/Button';
import { FC, useContext, useEffect, useState } from 'react';
import { forgotPassword, resetPassword } from '../../api/user';
import { useLocation, useNavigate } from "react-router-dom";

import Alert from '../Alert';
import Button from '../Button';
import Input from '../Input';
import { UserContext } from "../../contexts/UserContext";
import { checkPassword } from "../../api/auth";

type ResetPasswordProps = {
  isForgotPassword?: boolean;
}
const ResetPassword: FC<ResetPasswordProps> = ({ isForgotPassword }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [errorPassword, setErrorPassword] = useState<string>("");
  const [errorOldPassword, setErrorOldPassword] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const { user } = useContext(UserContext);
  const type = user?.type;
  const navigate = useNavigate();
  const token = searchParams.get('p');
  useEffect(() => {
    if ((password !== "" && passwordConfirm !== "") && (password !== passwordConfirm)) {
      setErrorPassword("Les mots de passe ne correspondent pas");
    } else {
      setErrorPassword("");
    }
  }
    , [password, passwordConfirm]);

  const handlePasswordChange = async () => {
    if (oldPassword === "") {
      setErrorOldPassword('');
      return;
    }
    try {
      const response = await checkPassword(user?.id, oldPassword, type);
      setErrorOldPassword('')
    } catch (error: any) {
      console.error(error);
      setErrorOldPassword(error.response.data.message)
    }
  };
  const modifyPassword = async () => {
    try {
      if (!isForgotPassword) {
        const response = await resetPassword(oldPassword, password, type);
        setSuccessMessage("Mot de passe modifié avec succès");
      } else {
        const response = await forgotPassword(token, password);
        navigate('/particuliers/login')
      }
    } catch (error: any) {
      console.log(error)
      setErrorMessage(error.response.data.error);
    }
  };
  return (
    <div className="ResetPassword">
      {errorMessage && <Alert type="error">{errorMessage}</Alert>}
      {successMessage && <Alert type="success">{successMessage}</Alert>}
      {!isForgotPassword &&
        <Input placeholder="Ancien mot de passe" type="password" onChange={(e) => setOldPassword(e.target.value)} onBlur={() => handlePasswordChange()} errorMessage={errorOldPassword} />}
      <Input placeholder="Nouveau mot de passe" type="password" onChange={(e) => setPassword(e.target.value)} />
      <Input
        placeholder="Confirmer le mot de passe"
        type="password"
        onChange={(e) => setPasswordConfirm(e.target.value)}
        errorMessage={errorPassword}
      />

      <Button
        style={isForgotPassword ? Button_Style.SOLID : Button_Style.OUTLINED}
        type={Button_Type.PRIMARY}
        label="Réinitialiser"
        onClick={() => modifyPassword()}
        disabled={errorPassword !== ""}
        className='m-auto'
      />
    </div>
  );
}

export default ResetPassword;
