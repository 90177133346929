import "./style.scss";

import { Button_Style, Button_Type } from "../Button/Button";
import { DocumentBasylic, DocumentStatusType } from "../../types/types";
import { FC, useContext, useEffect, useState } from "react";
import { checkDocument, getDocumentByNumber } from "../../api/lost";
import { documentRequest, documentRequestResponse } from "../../api/invitation";

import Alert from "../Alert";
import Button from "../Button";
import DocumentPreview from "../DocumentPreview";
import DocumentStatus from "../DocumentStatus";
import { ReactComponent as IconEye } from "../../assets/img/icons/icon_Eye.svg";
import Loader from "../Loader";
import Skeleton from "../Skeleton";
import SponsoCredits from "../SponsoCredits";
import { UserContext } from "../../contexts/UserContext";
import { createFile } from "../../api/analysis";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
type VerifyResultProps = {
    isScanning: boolean;
    documentVerified?: DocumentBasylic;
    refresh?: () => void;
    verifyReportURL?: string;
    errorMessage?: string;
    isDarkWeb?: boolean;
};
const VerifyResult: FC<VerifyResultProps> = ({ isScanning, documentVerified, refresh, verifyReportURL, errorMessage, isDarkWeb }) => {
    const { user } = useContext(UserContext);
    const [status, setStatus] = useState<DocumentStatusType>();
    const type = user?.type;
    const [requestId, setRequestId] = useState<string>("");
    const [isDocumentInBDD, setIsDocumentInBDD] = useState<boolean>(false);

    useEffect(() => {
        if (!documentVerified) return;
        if (isDarkWeb) {
            setStatus({
                conformite: documentVerified.conformite,
                darkWeb: documentVerified.darkWeb,
            });
        } else {
            check();
            getDocumentInBDD();
        }
    }, [documentVerified]);

    useEffect(() => {
        if (requestId) {
            let interval: NodeJS.Timeout;
            if (status?.idTracker === "confirm" || status?.idTracker === "reject") {
                return;
            }

            // Si status?.idTracker est "requested", commencez l'intervalle
            if (status?.idTracker === "requested" || status?.idTracker === undefined) {
                interval = setInterval(() => {
                    idTracker();
                }, 2000);
            }

            // Nettoyez l'intervalle si le composant est démonté ou si les conditions changent
            return () => {
                if (interval) {
                    clearInterval(interval);
                }
            };
        }
    }, [requestId, status?.idTracker]);

    useEffect(() => {
        if (isDocumentInBDD) {
            sendRequest();
        }
    }, [isDocumentInBDD]);
    const getDocumentInBDD = async () => {
        if (!documentVerified) return;
        try {
            const response = await getDocumentByNumber(documentVerified.numeroDocument, type);
            setIsDocumentInBDD(response.data.message !== "Aucun document suivi n'a été trouvé !" ? true : false);
        } catch (error: any) {
            console.error("Une erreur s'est produite :", error);
        }
    };

    const check = async () => {
        if (!documentVerified) return;
        try {
            const response = await checkDocument(documentVerified.numeroDocument, type);
            handleResponse(response);
        } catch (error: any) {
            console.error("Une erreur s'est produite :", error);
        }
    };

    const handleResponse = (response: any) => {
        const isLost = isDocumentLostOrStolen(response);
        const isLocked = isDocumentLocked(response);
        const isBlocked = isLost || isLocked;
        formatStatus(isLost, isLocked, isBlocked);
    };

    const isDocumentLostOrStolen = (response: any) => {
        return response.error ? true : false;
    };

    const isDocumentLocked = (response: any) => {
        return response.locked === "locked_document" || response.error ? true : false;
    };

    // ID Tracker
    const sendRequest = async () => {
        if (!documentVerified) return;
        try {
            const response = await documentRequest(documentVerified.numeroDocument, type);
            setRequestId(response.data.id);
            setStatus({
                ...status,
                idTracker: !isDocumentInBDD ? "notRequested" : status?.lost || status?.locked ? "reject" : "requested",
            });
        } catch (error: any) {
            console.log(error.response.data);
        }
    };

    const idTracker = async () => {
        if (!requestId) return;
        try {
            const idTracker = await documentRequestResponse(requestId, type);
            setStatus({
                ...status,
                idTracker: idTracker.data.request.status,
            });
        } catch (error: any) {
            console.log(error.response.data);
        }
    };
    const formatStatus = async (isLost: boolean, isLocked: boolean, isBlocked: boolean) => {
        // set idTracker to "requested" if the document is in the database, set to "reject" if the document is blocked, else set to "notRequested"
        if (!documentVerified) return;
        try {
            setStatus({
                conformite: documentVerified.conformite,
                darkWeb: documentVerified.darkWeb,
                lost: isLost,
                locked: isLocked,
                idTracker: !isDocumentInBDD ? "notRequested" : isBlocked ? "reject" : "requested",
            });
        } catch (error: any) {
            console.log(error.response.data);
        }
    };

    const visualiseFile = async () => {
        if (!verifyReportURL) return;
        try {
            const createFileResponse = await createFile(verifyReportURL);
            const file = createFileResponse.data;
            openPDFInNewTab(file.data, file.size, file.name);
        } catch (error: any) {
            console.log(error.response.data);
        }
    };

    const openPDFInNewTab = (base64Data: string, size: number, name: string) => {
        // Convert base64 to ArrayBuffer
        const binaryString = atob(base64Data);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        // Create a blob from ArrayBuffer
        const blob = new Blob([bytes.buffer], { type: "application/pdf" });

        // Create an Object URL from the blob
        const url = URL.createObjectURL(blob);

        // Open the Object URL in a new tab
        const newWindow = window.open(url, "_blank");

        // Optionally, you can set the new tab's title
        if (newWindow) {
            newWindow.document.title = name;
        }

        // Release the Object URL after use
        setTimeout(() => {
            URL.revokeObjectURL(url);
        }, 1000);
    };

    return (
        <div className="VerifyResult card">
            <h3>{isScanning ? "Analyse du document" : "Document vérifié"}</h3>
            {isScanning ? (
                <>
                    {isMobile && <Loader text="Analyse en cours. L'analyse peut prendre jusqu'à 30secondes." />}
                    <Skeleton
                        width="90%"
                        height={28}
                    />
                    <Skeleton
                        width="100%"
                        height={228}
                    />
                    <Skeleton
                        width="50%"
                        height={26}
                    />
                    <Skeleton
                        width="90%"
                        height={40}
                    />
                </>
            ) : (
                <>
                    {documentVerified && (
                        <>
                            {errorMessage && <Alert type="error">{errorMessage}</Alert>}
                            <DocumentPreview document={documentVerified} />
                            {status && (
                                <DocumentStatus
                                    status={status}
                                    documentType={documentVerified.typeDocument}
                                    isDarkWeb={isDarkWeb}
                                />
                            )}
                            {type === "individual" && <SponsoCredits />}
                            {type === "partner" && (
                                <Button
                                    label="Rapport ID Expert"
                                    type={Button_Type.SECONDARY}
                                    style={Button_Style.OUTLINED}
                                    className="m-auto mb-1"
                                    icon={<IconEye fill="red" />}
                                    onClick={() => visualiseFile()}
                                    disabled={!verifyReportURL}
                                    isLoader={!verifyReportURL}
                                />
                            )}
                            <Button
                                label="Vérifier un document"
                                type={type === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}
                                className="m-auto"
                                onClick={() => refresh && refresh()}
                            />
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default VerifyResult;
