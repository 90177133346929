import firebase from 'firebase';

var firebaseConfig = {
    apiKey: "AIzaSyDAou0TBa0j8EEX0MVG61zAJlm8rcryGA4",
    authDomain: "id-protect-staging.firebaseapp.com",
    databaseURL: "https://id-protect-staging-default-rtdb.firebaseio.com",
    projectId: "id-protect-staging",
    storageBucket: "id-protect-staging.appspot.com",
    messagingSenderId: "274153565242",
    appId: "1:274153565242:web:3fc6dd76e6fa20aae19903",
    measurementId: "G-5D9W5K9E0K"

}

firebase.initializeApp(firebaseConfig);
firebase.auth().useDeviceLanguage();
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

let auth = firebase.auth();
auth.languageCode = 'fr';
export { auth, firebase };