import "./style.scss";

import { Button_Style, Button_Type } from "../Button/Button";
import { FC, useState } from "react";

import Button from "../Button";
import EmptyContent from "../EmptyContent/EmptyContent";
import { ReactComponent as IconEye } from "../../assets/img/icons/icon_Eye.svg";
import { ReactComponent as IconNext } from "../../assets/img/icons/icon_carret-Right.svg";
import { ReactComponent as IconPrev } from "../../assets/img/icons/icon_carret-Left.svg";
import NationalityBadge from "../NationalityBadge";
import React from "react";
import ToggleIdNumber from "../ToggleIdNumber";
import { createFile } from "../../api/analysis";
import moment from "moment";

type TableProProps = {
    rows: any[];
    columns: any[];
};

const ITEMS_PER_PAGE = 10;

const documentTypeMap: { [key: string]: string } = {
    ID: "Carte d'identité",
    id: "Carte d'identité",
    "Carte nationale d'identité française": "Carte d'identité",
    P: "Passeport",
    p: "Passeport",
    passeport: "Passeport",
    "Passeport français": "Passeport",
    permis: "Permis",
    "Permis de conduire": "Permis",
    S: "Carte de séjour",
    " Titre de séjour français": "Carte de séjour",
};

const formatDocumentType = (value: string): string => {
    return documentTypeMap[value] ?? "Inconnu";
};

const TablePro: FC<TableProProps> = ({ rows, columns }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(rows.length / ITEMS_PER_PAGE);
    const getVisibleRows = () => {
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, rows.length);
        return rows.slice(startIndex, endIndex);
    };
    const formatData = (key: string, data: any, id: number) => {
        switch (key) {
            case "idType":
                return <div className="td--type">{formatDocumentType(data)}</div>;
            case "documentNumber":
                return (
                    <div className="td--idNumber">
                        <ToggleIdNumber number={data} />
                    </div>
                );
            case "createdAt":
                return <div className="td--createdAt">{moment(data).format("DD/MM/YYYY")}</div>;
            case "controls":
                return (
                    <span className={`tag ${data === "" || !data ? "tag-success" : "tag-error"}`}>
                        {data === "" || !data ? "Conforme" : "Non conforme"}
                    </span>
                );
            case "validity":
                return (
                    data !== "OK" && (
                        <span className={`tag ${data === "OK" || data === "ACCEPTED" ? "tag-success" : "tag-error"}`}>
                            {data === "OK" || data === "ACCEPTED" ? "Accepté" : "Refusé"}
                        </span>
                    )
                );
        }
    };

    const visualiseFile = async (verifyReportURL: string) => {
        if (!verifyReportURL) return;
        try {
            const createFileResponse = await createFile(verifyReportURL);
            const file = createFileResponse.data;
            openPDFInNewTab(file.data, file.size, file.name);
        } catch (error: any) {
            console.log(error.response.data);
        }
    };

    const openPDFInNewTab = (base64Data: string, size: number, name: string) => {
        // Convert base64 to ArrayBuffer
        const binaryString = atob(base64Data);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        // Create a blob from ArrayBuffer
        const blob = new Blob([bytes.buffer], { type: "application/pdf" });

        // Create an Object URL from the blob
        const url = URL.createObjectURL(blob);

        // Open the Object URL in a new tab
        const newWindow = window.open(url, "_blank");

        // Optionally, you can set the new tab's title
        if (newWindow) {
            newWindow.document.title = name;
        }

        // Release the Object URL after use
        setTimeout(() => {
            URL.revokeObjectURL(url);
        }, 1000);
    };

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th></th>
                        {columns.map((column) => (
                            <th
                                key={column.key}
                                className={column.key === "creationDate" ? "text-center" : ""}>
                                {column.label}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td
                            colSpan={columns.length}
                            style={{ height: 10, opacity: 0 }}
                        />
                    </tr>
                    {getVisibleRows().map((row: any, index: number) => (
                        <React.Fragment key={index}>
                            <tr>
                                <td>
                                    <NationalityBadge />
                                </td>
                                {columns.map((column: any) => (
                                    <td key={column.key}>{formatData(column.key, row[column.key], row.id)}</td>
                                ))}
                                <td key={index}>
                                    <Button
                                        isIconButton
                                        type={Button_Type.SECONDARY}
                                        style={Button_Style.OUTLINED}
                                        icon={<IconEye />}
                                        onClick={() => visualiseFile(row.otherDetails)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td
                                    colSpan={columns.length}
                                    style={{ height: 10, opacity: 0 }}
                                />
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
            {rows.length === 0 && <EmptyContent text="Vous n'avez aucun document enregistrés" />}
            {totalPages > 1 && (
                <div className="pagination">
                    <Button
                        onClick={goToPreviousPage}
                        disabled={currentPage === 1}
                        icon={<IconPrev fill="#1956A8" />}
                        style={Button_Style.OUTLINED}
                        type={Button_Type.PRIMARY}
                        isIconButton
                    />
                    <Button
                        onClick={goToNextPage}
                        disabled={currentPage === totalPages}
                        icon={<IconNext fill="#1956A8" />}
                        style={Button_Style.OUTLINED}
                        type={Button_Type.PRIMARY}
                        isIconButton
                    />
                    <span>
                        Page {currentPage} sur {totalPages}
                    </span>
                </div>
            )}
        </>
    );
};

export default TablePro;
