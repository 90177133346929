import "./style.scss";

import * as Yup from "yup";

import { FC, useContext, useState } from "react";
import { Form, Formik } from "formik";

import Button from "../Button";
import { Button_Style } from "../Button/Button";
import IndicatifPhone from "../IndicatifPhone";
import Input from "../Input";
import { UserContext } from "../../contexts/UserContext";

interface IDAssistantInfoFormProps {
    steps: any;
    setDatas: (data: any) => void;
    datas: any;
    handlePreviousStep: () => void;
    handleNextStep: () => void;
}

const IDAssistantInfoForm: FC<IDAssistantInfoFormProps> = ({ steps, setDatas, datas, handleNextStep, handlePreviousStep }) => {
    const [phonePrefix, setPhonePrefix] = useState("+33");
    const { user } = useContext(UserContext);
    return (
        <div className="IDAssistantInfoForm h-100">
            {
                <Formik
                    initialValues={{
                        firstName: user?.firstname || "",
                        lastName: user?.lastname || "",
                        addresse: "",
                        city: "",
                        postalCode: "",
                        phoneNumber: user?.phoneNumber || "",
                    }}
                    validationSchema={Yup.object({
                        firstName: Yup.string()
                            .min(2, "Le prénom doit contenir au moins 2 caractères.")
                            .max(50, "Le prénom ne peut pas dépasser 50 caractères.")
                            .matches(/^[a-zA-Z\s]*$/, "Seules les lettres et les espaces sont autorisés.")
                            .required("Le prénom est requis"),
                        lastName: Yup.string()
                            .min(2, "Le nom doit contenir au moins 2 caractères.")
                            .max(20, "Le nom ne peut pas dépasser 20 caractères.")
                            .matches(/^[a-zA-Z\s]*$/, "Seules les lettres et les espaces sont autorisés.")
                            .required("Le nom est requis"),
                        addresse: Yup.string().required("Ce champ est requis"),
                        city: Yup.string().required("Ce champ est requis"),
                        postalCode: Yup.string().required("Ce champ est requis"),
                        phoneNumber: Yup.string()
                            .matches(/^([0-9][0-9]{9})$/, "Numéro de téléphone invalide")
                            .required("Le téléphone est requis"),
                    })}
                    onSubmit={(values) => {
                        setDatas({ ...datas, ...values });
                        handleNextStep();
                    }}>
                    {(formik) => (
                        <Form className="w-100 h-100 d-flex flex-column align-items-center justify-content-between">
                            <div>
                                <h3>Vos informations personnelles</h3>
                                <p className="mb-2">Renseignez vos informations personnelles</p>
                                <Input
                                    onChange={formik.handleChange}
                                    value={formik.values.firstName}
                                    onBlur={formik.handleBlur}
                                    required={true}
                                    placeholder="Prénom"
                                    name="firstName"
                                    type="text"
                                    errorMessage={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : ""}
                                />
                                <Input
                                    onChange={formik.handleChange}
                                    value={formik.values.lastName}
                                    onBlur={formik.handleBlur}
                                    required={true}
                                    placeholder="Nom"
                                    name="lastName"
                                    type="text"
                                    errorMessage={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : ""}
                                />
                                <Input
                                    required={true}
                                    placeholder="Adresse"
                                    name="addresse"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.addresse}
                                    onBlur={formik.handleBlur}
                                    errorMessage={formik.touched.addresse && formik.errors.addresse ? formik.errors.addresse : ""}
                                />
                                <Input
                                    placeholder="Ville"
                                    name="city"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.city}
                                    onBlur={formik.handleBlur}
                                    required={true}
                                    errorMessage={formik.touched.city && formik.errors.city ? formik.errors.city : ""}
                                />
                                <Input
                                    placeholder="Code postal"
                                    name="postalCode"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.postalCode}
                                    onBlur={formik.handleBlur}
                                    required={true}
                                    errorMessage={formik.touched.postalCode && formik.errors.postalCode ? formik.errors.postalCode : ""}
                                />
                                <div className="d-flex gap-1 align-items-end">
                                    <IndicatifPhone
                                        setPhonePrefix={setPhonePrefix}
                                        value={phonePrefix}
                                    />
                                    <Input
                                        placeholder="Numéro de téléphone"
                                        name="phoneNumber"
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={formik.values.phoneNumber}
                                        onBlur={formik.handleBlur}
                                        required={true}
                                        errorMessage={
                                            formik.touched.phoneNumber && formik.errors.phoneNumber ? formik.errors.phoneNumber : ""
                                        }
                                    />
                                </div>
                            </div>

                            <div className={`d-flex justify-content-${!steps[0].isCurrent ? "between" : "end"} w-100`}>
                                {!steps[0].isCurrent && (
                                    <Button
                                        label={"Retour"}
                                        onClick={() => handlePreviousStep()}
                                        style={Button_Style.OUTLINED}
                                    />
                                )}
                                <Button
                                    buttonType={"submit"}
                                    label={"Suivant"}
                                    disabled={!formik.isValid && formik.dirty}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            }
        </div>
    );
};

export default IDAssistantInfoForm;
