import './style.scss';

import { FC, useEffect, useRef, useState } from 'react';

import { Options } from '../../types/types';

type SelectProps = {
  placeholder?: string;
  value?: string;
  options: Options[];
  required?: boolean;
  className?: string;
  handleChange?: (value: string) => void; // New prop for handleChange
} & Record<string, any>;

const Select: FC<SelectProps> = ({ options, placeholder, className, value, handleChange, ...rest }) => { // Include handleChange in the props
  const [isOpened, setIsOpened] = useState(false);

  const selectRef = useRef<HTMLDivElement>(null);

  const handleSelectToggle = () => {
    setIsOpened(!isOpened);
  };

  const handleOptionSelect = (optionValue: string) => {

    setIsOpened(false);
    if (handleChange) {
      handleChange(optionValue);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='select-wrapper' ref={selectRef}>
      <select className={`select ${className}`} {...rest} />
      <div className={`custom-select ${isOpened ? 'opened' : ''}`}>
        <div className={`custom-select__trigger ${className}`} onClick={handleSelectToggle}>
          <span className={value && "isFiled"}>{value || placeholder}</span>
        </div>
        {<div className="custom-options">
          {options.map((option, index) => (
            <span
              className="custom-option"
              key={index}
              data-value={option}
              onClick={() => handleOptionSelect(option.value)}
            >
              {option.label}
            </span>
          ))}
        </div>}
      </div>
    </div>
  );
}

export default Select;
