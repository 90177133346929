import './style.scss';
import 'react-toggle/style.css';

import React, { FC, useState } from 'react';

import Toggle from 'react-toggle';

const ToggleButton = ({ ...props }) => {
  return (
    <Toggle {...props} />
  );
}

export default ToggleButton;
