import { DocumentBasylic, ErrorType } from "../types/types";
import { FC, useEffect, useState } from "react";

import AddDocumentForm from "../components/AddDocumentForm";
import AddDocumentInfos from "../components/AddDocumentInfos";
import DocumentIllustration from "../components/DocumentIllustration/DocumentIllustration";
import ErrorScan from "../components/ErrorScan";
import Loader from "../components/Loader/Loader";
import Toast from "../components/Toast/Toast";
import { useNavigate } from "react-router-dom";

const IDTracker: FC = () => {
    const [type, setType] = useState<string>("");
    const [isScanning, setIsScanning] = useState<boolean>(false);
    const [isScanned, setIsScanned] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [document, setDocument] = useState<DocumentBasylic>();
    const [errorType, setErrorType] = useState<ErrorType>({ status: 0, message: "" });
    const navigate = useNavigate();
    const handleTypeChange = (type: string) => {
        setType(type);
    };

    // reset component state
    const resetState = () => {
        setType("");
        setIsScanning(false);
        setIsScanned(false);
        setErrorMessage("");
        setDocument(undefined);
        setErrorType({ status: 0, message: "" });
    };

    useEffect(() => {
        resetState();
    }, [navigate]);

    return (
        <div className="VerifyPage content__body">
            <div className="VerifyPage__row row">
                <div className="VerifyPage__content col-sm-6">
                    {!isScanning && !isScanned && errorType?.status !== 400 ? (
                        <AddDocumentForm
                            onChangeType={handleTypeChange}
                            type={type}
                            isScanning={isScanning}
                            setIsScanning={setIsScanning}
                            setIsScanned={setIsScanned}
                            setErrorMessage={setErrorMessage}
                            setDocument={setDocument}
                            errorType={errorType}
                            setErrorType={setErrorType}
                        />
                    ) : errorType?.status !== 400 ? (
                        <>
                            <AddDocumentInfos
                                isScanning={isScanning}
                                document={document}
                            />
                        </>
                    ) : (
                        <ErrorScan
                            error={errorType?.message}
                            resetState={resetState}
                        />
                    )}
                </div>
                <div className="VerifyPage__illu col-sm-6 center">
                    <div>
                        <h1 className="VerifyPage__title text-center mb-4">Ajouter une pièce d'identité</h1>
                        <DocumentIllustration type={type} />
                        {isScanning && <Loader text="Analyse en cours. L'analyse peut prendre jusqu'à 30secondes." />}
                    </div>
                </div>
            </div>
            {errorMessage && (
                <Toast
                    type="error"
                    message={errorMessage}
                />
            )}
        </div>
    );
};
export default IDTracker;
