import type { Plugin, RenderViewer } from '@react-pdf-viewer/core';

import React from 'react';

export interface PDFThumbnailProps {
  PageThumbnail: React.ReactElement;

}
const PDFThumbnailPlugin = (props: PDFThumbnailProps): Plugin => {
  const { PageThumbnail } = props;
  return {
    renderViewer: (renderProps: RenderViewer) => {
      let { slot } = renderProps;
      slot.children = PageThumbnail;

      // Reset the sub slot
      if (!slot.subSlot) {
        slot.subSlot = {};
      }
      slot.subSlot.attrs = {};
      slot.subSlot.children = <></>;

      return slot;
    },
  };
}

export default PDFThumbnailPlugin;
