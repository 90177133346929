import { Route, Routes } from "react-router-dom";

import CreditsPricing from "./pages/CreditsPricing";
import Dashboard from "./pages/Dashboard";
import Document from "./pages/DocumentDetail";
import Faq from "./pages/Faq";
import FilterPage from "./pages/FilterPage";
import Folder from "./pages/IDLockerDetail";
import { FolderContextProvider } from "./contexts/FoldersContext";
import IDAssistantAdd from "./pages/IDAssistantAdd";
import IDAssistantDetail from "./pages/IDAssistantDetail";
import IDAssistantList from "./pages/IDAssistantList";
import IDLocker from "./pages/IDLocker";
import IDLockerList from "./pages/IDLockerList";
import IDTracker from "./pages/IDTracker";
import IDTrackerPricing from "./pages/IDTrackerPricing";
import Layout from "./components/Layout";
import ListIDs from "./pages/DocumentsList";
import NotFoundPage from "./pages/NotFoundPage";
import Profil from "./pages/Profil";
import StripePayment from "./pages/StripePayment";
import Verify from "./pages/Verify";

const ConditionalRoutes = () => {
    const type = localStorage.getItem("type");

    if (type === "individual") {
        return (
            <Routes>
                <Route
                    path="/particuliers/dashboard"
                    element={
                        <FolderContextProvider userType={type}>
                            <Layout>
                                <Dashboard />
                            </Layout>
                        </FolderContextProvider>
                    }
                />
                <Route
                    path="/particuliers/pieces-identite"
                    element={
                        <Layout>
                            <ListIDs />
                        </Layout>
                    }
                />

                <Route
                    path="/mes-documents/:id"
                    element={
                        <Layout>
                            <Document />
                        </Layout>
                    }
                />
                <Route
                    path="/particuliers/id-assistant"
                    element={
                        <Layout>
                            <IDAssistantList />
                        </Layout>
                    }
                />
                <Route
                    path="/particuliers/id-assistant/add/:offer"
                    element={
                        <Layout>
                            <IDAssistantAdd />
                        </Layout>
                    }
                />
                <Route
                    path="/particuliers/id-assistant/:id"
                    element={
                        <Layout>
                            <IDAssistantDetail />
                        </Layout>
                    }
                />
                <Route
                    path="/particuliers/id-locker/:id"
                    element={
                        <FolderContextProvider userType={type}>
                            <Layout>
                                <Folder />
                            </Layout>
                        </FolderContextProvider>
                    }
                />
                <Route
                    path="/particuliers/id-locker/"
                    element={
                        <FolderContextProvider userType={type}>
                            <Layout>
                                <IDLockerList />
                            </Layout>
                        </FolderContextProvider>
                    }
                />
                <Route
                    path="/particuliers/id-assistant/add/"
                    element={
                        <FolderContextProvider userType={type}>
                            <Layout>
                                <IDAssistantAdd />
                            </Layout>
                        </FolderContextProvider>
                    }
                />
                <Route
                    path="/particuliers/idtracker"
                    element={
                        <Layout>
                            <IDTracker />
                        </Layout>
                    }
                />
                {/* <Route
                    path="/particuliers/declarer-une-perte"
                    element={
                        <Layout>
                            <DeclarationPerte />
                        </Layout>
                    }
                /> */}

                <Route
                    path="/particuliers/profil"
                    element={
                        <Layout>
                            <Profil />
                        </Layout>
                    }
                />
                <Route
                    path="/particuliers/verifier-document"
                    element={
                        <Layout>
                            <Verify />
                        </Layout>
                    }
                />
                <Route
                    path="/particuliers/dark-web"
                    element={
                        <Layout>
                            <Verify isDarkWeb />
                        </Layout>
                    }
                />
                <Route
                    path="/particuliers/faq"
                    element={
                        <Layout>
                            <Faq />
                        </Layout>
                    }
                />
                <Route
                    path="/particuliers/filter"
                    element={
                        <Layout>
                            <FilterPage />
                        </Layout>
                    }
                />

                <Route
                    path="/particuliers/credits-pricing"
                    element={
                        <Layout>
                            <CreditsPricing />
                        </Layout>
                    }
                />
                <Route
                    path="/particuliers/id-tracker-pricing"
                    element={
                        <Layout>
                            <IDTrackerPricing />
                        </Layout>
                    }
                />
                <Route
                    path="/particuliers/payment/:offer/:planIndex/:isMonth"
                    element={
                        <Layout>
                            <StripePayment />
                        </Layout>
                    }
                />
                <Route
                    path="*"
                    element={
                        <Layout>
                            <NotFoundPage />
                        </Layout>
                    }></Route>
            </Routes>

            // ... fin des routes pour les particuliers ...
        );
    }
    // else if (type === 'partner') {
    //   return (

    //     <Routes>
    //     <Route
    //       path="/professionnels/dashboard"
    //       element={
    //         <Layout>
    //           <DashboardPro />
    //         </Layout>
    //       }
    //     />
    //     <Route
    //       path="/professionnels/verifier-document"
    //       element={
    //         <Layout>
    //           <VerificationPro/>
    //         </Layout>
    //       }
    //     />
    //     </Routes>

    //     // ... autres routes pour les professionnels ...
    //   );
    // }
    else {
        return <Routes></Routes>;
    }
};

export default ConditionalRoutes;
