import './style.scss'; 
 
import React, { FC } from 'react';
import logoVerification from "../../assets/img/Verification.svg";
import Button from "../Button";
import PriceCard from "../PriceCard/PriceCard";
import {Pricing} from "../../constants/pricings";

interface IDAssistantPricingProps {
    plan: Pricing;
    planIndex: number;
    handleNext: (i: number) => void;
}


const CreditsPricingCard: FC<IDAssistantPricingProps> = ({ planIndex, plan, handleNext }) => {
  return ( 
    <div className="CreditsPricingCard">
        <PriceCard
            title={plan.title}
            logo={logoVerification}
            plan={plan}
            >

            <Button
                label="Acheter"
                size="L"
                onClick={() => handleNext(planIndex)}
            />
        </PriceCard>
    </div> 
  ); 
} 
 
export default CreditsPricingCard; 
