import "./style.scss";

import React, { FC } from "react";

import { ReactComponent as IconCheck } from "../../assets/img/icons/icon_Check.svg";
import MoneyDisplay from "../MoneyDisplay";
import { Pricing } from "../../constants/pricings";

interface PriceCardDetailProps {
    plan?: Pricing | null;
    features?: string[] | null;
    isMonth?: boolean;
}

const PriceCardDetail: FC<PriceCardDetailProps> = ({ plan, isMonth, features }) => {
    return (
        <div className="PriceCardDetail">
            <MoneyDisplay
                plan={plan}
                isMonth={isMonth}
            />
            <h3>{plan?.title}</h3>
            <ul>
                {features?.map((feature, index) => (
                    <>
                        <li className={plan?.featuresRange?.includes(index) || plan?.features ? "within" : "without"}>
                            <span className="icon-check">
                                <IconCheck
                                    fill="#fff"
                                    width={16}
                                    height={16}
                                />
                            </span>
                            <span>{feature}</span>
                        </li>
                    </>
                ))}
            </ul>
        </div>
    );
};

export default PriceCardDetail;
