import "./style.scss";

import React, { FC } from "react";

import { ReactComponent as IconChecked } from "../../assets/img/icons/icon_status-Checked.svg";
import { ReactComponent as IconError } from "../../assets/img/icons/icon_Error.svg";
import { ReactComponent as IconInfo } from "../../assets/img/icons/icon_Info.svg";
import { ReactComponent as IconWarning } from "../../assets/img/icons/icon_Warning.svg";

type AlertType = {
    type: "error" | "success" | "warning" | "info";
    children: React.ReactNode;
};
//
const Alert: FC<AlertType> = ({ type, children }) => {
    const iconMap = {
        error: (
            <IconError
                width="16px"
                fill="#FF5326"
            />
        ),
        success: (
            <IconChecked
                width="16px"
                fill="#17EB79"
            />
        ),
        warning: (
            <IconWarning
                width="16px"
                fill="#FF8833"
            />
        ),
        info: (
            <IconInfo
                width="16px"
                fill="#1956A8"
            />
        ),
    };

    const icon = iconMap[type] || (
        <IconInfo
            width="16px"
            fill="#1956A8"
        />
    );

    return (
        <div className={`alert alert-${type}`}>
            {icon}
            <div className="alert__content">{children}</div>
        </div>
    );
};

export default Alert;
