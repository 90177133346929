import Accordion from "../components/Accordion";
import { FC } from "react";
import PageHeader from "../components/PageHeader";
import faqs from '../assets/dataTests/FAQ.json';

const Faq: FC = () => {
	const documentType: { [key: string]: string } = {
		"id": "Carte d'identité",
		"passeport": "Passport",
		"permis": "Permis de conduire",
		"sejour": "Titre de séjour",
	};

	const getDocumentType = (type?: string) => {
		return type && documentType[type];
	};
	return (
		<>
			<PageHeader>
				<h5>FAQ</h5>
			</PageHeader>
			<div className="content__body">
				<div className="row">
					<div className="col-lg-9">
						{faqs.map((type, index) => (
							<div key={index}>
								<h4>{getDocumentType(type.type)}</h4>
								{type.faqs.map((faq, index) => (
									<Accordion title={faq.question} key={index}>
										<div dangerouslySetInnerHTML={{ __html: faq.answer }} />
									</Accordion>
								))}
							</div>
						))}
					</div>

				</div>
			</div>
		</>
	)
}

export default Faq;