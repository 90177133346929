import './style.scss';

import React, { FC, useEffect, useState } from 'react';

import { ReactComponent as IconCarretDown } from "../../assets/img/icons/icon_carret-Down.svg";
import { ReactComponent as IconCarretUp } from "../../assets/img/icons/icon_carret-Up.svg";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
type SnackbarProps = {
  children: React.ReactNode;
  title: string;
  expanded?: boolean;
};
const Snackbar: FC<SnackbarProps> = ({ children, title, expanded }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (!isMobile) {
      if (expanded) {
        setIsExpanded(expanded)
      }
    } else {
      setIsExpanded(false)
    }
  }, [expanded])

  const toggleSnackbar = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`snackbar ${isExpanded ? 'expanded' : ''} ${isMobile && 'mb-1'}`}>
      <div className="snackbar__title" onClick={toggleSnackbar}>
        {title}
        <button className="expand-button" >
          {isExpanded ? <IconCarretUp width={16} fill="#1956A8" /> : <IconCarretDown width={16} fill="#1956A8" />}
        </button>
      </div>
      <div className="snackbar-content">
        {children}
      </div>
    </div>
  );
};

export default Snackbar;
