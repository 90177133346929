import "./style.scss";

import { FC, useContext } from "react";

import { AccompaniementsContext } from "../../contexts/AccompaniementsContext";
import { AccompanimentType } from "../../types/types";
import EmptyContent from "../EmptyContent";
import FolderCard from "../FolderCard";
import { useLocation } from "react-router-dom";

const AccompaniementsList: FC = () => {
    const location = useLocation();
    const pathname = location.pathname.split("/").slice(2).join("/");
    const { accompaniments } = useContext(AccompaniementsContext);

    return (
        <div className="AccompaniementsList mb-2">
            {accompaniments && accompaniments.length > 0 ? (
                accompaniments?.map((accompaniment: AccompanimentType) => {
                    if (!accompaniment) return null;
                    const formatDate = (date: string) => {
                        const d = new Date(date);
                        return `${d.getDate()}/${d.getMonth() < 10 && "0" + d.getMonth()}/${d.getFullYear()}`;
                    };
                    return (
                        <FolderCard
                            key={accompaniment.id}
                            name={formatDate(accompaniment.createdAt)}
                            id={accompaniment.id}
                            pathname={pathname}
                            hideActions
                        />
                    );
                })
            ) : (
                <EmptyContent text="Aucun dossiers" />
            )}
        </div>
    );
};

export default AccompaniementsList;
