import axios from "../../axiosConfig";

// eslint-disable-next-line react-hooks/rules-of-hooks

 export async function getNotifications(type) {
  try {
	  const response = await axios.post('/client/notifications',{ type });
    return response.data.notifs;
  } catch (error) {
    return error;
  }
}

  export async function sendNotification (type, notification) {
    const body = new FormData()
    body.append('notification', JSON.stringify(notification))
    body.append('type', type)
    const response = await axios.post('/client/notification', body)
    if (response) {
      this.getNotifcations(type);
      return response
    }
  }

export async function updateNotification(type) {
	const response = await axios.put('/client/notification/updateState',{ type })
	return response
 }
export async function updateNotificationSingle(type, idNotif) {
	const response = await axios.put(`/client/notification/updateStateById`,{ type: type, notificationId: idNotif })
	return response
 }

export async function unseenNotifications(type) {
	const response = await axios.post('/client/notification/notseen',{ type })
	return response
}
