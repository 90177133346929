import { FC } from "react";
import ResetPassword from "../components/ResetPassword";
import svgImage from "../assets/img/Illustration_documents.svg";

const ResetPasswordPage: FC = () => {
    return (
        <>
            <main className="login">
                <div className="login__container">
                    <div className="login__form">
                        <h5>Espace Particulier</h5>
                        <h2>Réinitialisé le mot de passe</h2>
                        <ResetPassword isForgotPassword />
                    </div>
                    <div className="login__img">
                        <img
                            src={svgImage}
                            className="illustration-document"
                            alt="Login"
                        />
                    </div>
                </div>
            </main>
        </>
    );
};

export default ResetPasswordPage;
