import "./style.scss";

import React, { DragEvent, useContext, useEffect, useState } from "react";
import { deleteProfilePicture, updateProfilePicture } from "../../api/user";

import Button from "../Button";
import { Button_Type } from "../Button/Button";
import { ReactComponent as IconDelete } from "../../assets/img/icons/icon_Close.svg";
import { ReactComponent as IconUpload } from "../../assets/img/icons/icon_Upload.svg";
import { UserContext } from "../../contexts/UserContext";

interface UploadProfilePictureProps {
    // Define any props here if needed
}

const UploadProfilePicture: React.FC<UploadProfilePictureProps> = (props) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | null>(null);
    const { user, userType } = useContext(UserContext);

    useEffect(() => {
        if (!user?.picture) return;
        const parts = user?.picture.split("/");
        const fileName = parts[parts.length - 1];
        setPreview(`https://api.staging.idprotect.fr/api/client/getImage/${user?.clientNumber}/${fileName}`);
    }, [user?.picture]);

    useEffect(() => {
        handleUpload();
    }, [selectedFile]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0];
            if (file.size > 5242880) {
                alert("La taille du fichier doit faire moins de 1MB");
                return;
            }
            setSelectedFile(file);
            setPreview(URL.createObjectURL(file));
        }
    };

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const file = e.dataTransfer.files[0];
            if (file.size > 5242880) {
                alert("La taille du fichier doit faire moins de 1MB");
                return;
            }
            setSelectedFile(file);
            setPreview(URL.createObjectURL(file));
            e.dataTransfer.clearData();
        }
    };

    const handleUpload = async (): Promise<void> => {
        if (!selectedFile) return;
        try {
            updateProfilePicture(userType, selectedFile);
        } catch (error) {
            console.error("Error reading file: ", error);
        }
    };

    const handleDeleteImage = () => {
        if (user?.picture) {
            deleteProfilePicture(userType);
        }
        setSelectedFile(null);
        setPreview(null);
    };

    return (
        <div className="upload-container">
            <div
                className="drop-area"
                onDragOver={handleDragOver}
                onDrop={handleDrop}>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="fileInput"
                />
                {!preview && (
                    <label
                        htmlFor="fileInput"
                        className="pointer">
                        <IconUpload fill="#1956A8" />
                        Votre photo de profil
                    </label>
                )}
                {preview && (
                    <label htmlFor="fileInput">
                        <img
                            src={preview}
                            alt="Preview"
                            className="image-preview pointer"
                        />
                        <Button
                            onClick={handleDeleteImage}
                            isIconButton
                            icon={<IconDelete fill="#1956A8" />}
                            type={Button_Type.WHITE}
                            className="delete-button"
                        />
                    </label>
                )}
            </div>
        </div>
    );
};

export default UploadProfilePicture;
