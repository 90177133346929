import "../assets/scss/style.scss";

import { FC, useState } from "react";

import ConfirmEmail from "../components/ConfirmEmail";
import RegisterForm from "../components/RegisterForm";
import svgImage from "../assets/img/Illustration_documents.svg";

const Register: FC = () => {
    const [showConfirmEmail, setShowConfirmEmail] = useState(false);
    const [confirmEmailMessage, setConfirmEmailMessage] = useState("");

    return (
        <main className="login">
            <div className="login__container">
                <div className="login__form">
                    {!showConfirmEmail && (
                        <RegisterForm
                            showConfirmEmail={setShowConfirmEmail}
                            setConfirmEmailMessage={setConfirmEmailMessage}
                        />
                    )}
                    {showConfirmEmail && <ConfirmEmail confirmEmailMessage={confirmEmailMessage} />}
                </div>
                <div className="login__img">
                    <img
                        src={svgImage}
                        className="illustration-document"
                        alt="Register"
                    />
                </div>
            </div>
        </main>
    );
};

export default Register;
