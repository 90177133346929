import "./style.scss";

import * as Yup from "yup";

import Button, { Button_Type } from "../Button/Button";
import React, { useEffect, useState } from "react";
import { auth, firebase } from "../../config/firebase";
import { useLocation, useNavigate } from "react-router-dom";

import Alert from "../Alert";
import Checkbox from "../Checkbox";
import ConfirmPhone from "../ConfirmPhone";
import IndicatifPhone from "../IndicatifPhone";
import Input from "../Input/Input";
import Loader from "../Loader";
import { useFormik } from "formik";

interface RegisterFormProps {
    showConfirmEmail: (value: boolean) => void;
    setConfirmEmailMessage: (value: string) => void;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ showConfirmEmail, setConfirmEmailMessage }) => {
    const [final, setfinal] = useState<firebase.auth.ConfirmationResult | null>(null); //The final result of the sendOTP request
    const [showConfirmPhone, setshowConfirmPhone] = useState(false); //A boolean value that indicates the register or confirmphone componnent is visible
    const [recaptcha, setRecaptcha] = useState<firebase.auth.ApplicationVerifier>();
    let verifier: any = null;
    const [errorMessage, setErrorMessage] = useState("");
    const [userType, setUserType] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [phonePrefix, setPhonePrefix] = useState("+33");
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const validationSchema = Yup.object({
        firstname: Yup.string()
            .min(2, "Le prénom doit contenir au moins 2 caractères.")
            .max(50, "Le prénom ne peut pas dépasser 50 caractères.")
            .matches(/^[a-zA-Z\s]*$/, "Seules les lettres et les espaces sont autorisés.")
            .required("Le prénom est requis"),
        lastname: Yup.string()
            .min(2, "Le nom doit contenir au moins 2 caractères.")
            .max(20, "Le nom ne peut pas dépasser 20 caractères.")
            .matches(/^[a-zA-Z\s]*$/, "Seules les lettres et les espaces sont autorisés.")
            .required("Le nom est requis"),
        email: Yup.string().email("Email invalide").required("L'email est requis"),
        password: Yup.string()
            .min(8, "Le mot de passe doit contenir au moins 8 caractères.")
            .matches(/[a-zA-Z]/, "Le mot de passe doit contenir au moins une lettre.")
            .matches(/[0-9]/, "Le mot de passe doit contenir au moins un chiffre.")
            .required("Veuillez entrer un mot de passe."),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("password")], "Les mots de passe doivent correspondre")
            .required("Le mot de passe de confirmation est requis"),
        phoneNumber: Yup.string()
            .matches(/^([0-9][0-9]{9})$/, "Numéro de téléphone invalide")
            .required("Le téléphone est requis"),
    });

    const [RegistrationData, setRegistrationData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        phoneNumber: "",
        type: "",
    });
    const generateCaptcha = async () => {
        try {
            if (!verifier) {
                verifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
                    "expired-callback": function () {
                        setErrorMessage("Le captcha a expiré. SVP merci de recharger la page et essayer de nouveau.");
                    },
                });
                await verifier.verify();
                setRecaptcha(verifier);
                setErrorMessage("");
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        generateCaptcha();
    }, []);
    useEffect(() => {
        const hasProfessionnels = location.pathname.includes("professionnels");
        setUserType(hasProfessionnels ? "partner" : "individual");
    }, [location]);

    const formik = useFormik({
        initialValues: {
            firstname: "",
            lastname: "",
            email: "",
            password: "",
            confirmPassword: "",
            phoneNumber: "",
            companyName: "",
            type: userType,
        },
        validationSchema,
        onSubmit: async (values) => {
            setIsLoading(true);
            try {
                values.type = userType;
                if (userType === "individual") {
                    let { companyName, confirmPassword, ...data } = values;
                    setRegistrationData(data);
                    // const response = await register(data);
                    // setConfirmEmailMessage(response.success);
                } else {
                    let { confirmPassword, ...data } = values;
                    setRegistrationData(data);
                    // const response = await register(data);
                    // setConfirmEmailMessage(response.success);
                }
                await SendOtp(values.phoneNumber);
                setIsLoading(false);
            } catch (error: any) {
                setIsLoading(false);
                if (error.message === "Network Error") {
                    setErrorMessage("Erreur réseau, veuillez réessayer plus tard.");
                } else {
                    setErrorMessage(error.response.data.error);
                }
            }
        },
    });
    const getFullPhoneNumber = (phoneNumber: string) => {
        if (phoneNumber.charAt(0) === "0") {
            phoneNumber = phoneNumber.substr(1);
        }
        const fullPhoneNumber = `${phonePrefix}${phoneNumber}`;
        return fullPhoneNumber;
    };
    // Send OTP
    const SendOtp = async (phoneNumber: string) => {
        const fullPhoneNumber = getFullPhoneNumber(phoneNumber);
        setIsLoading(true);
        try {
            if (fullPhoneNumber === "" || fullPhoneNumber.length < 10) return;
            if (recaptcha) {
                const result = await auth.signInWithPhoneNumber(fullPhoneNumber, recaptcha);
                //verifier.clear();
                setIsLoading(false);
                setfinal(result); // Mise à jour de l'état
                setshowConfirmPhone(true); // Affiche l'UI de confirmation du numéro de téléphone
            }
        } catch (error: any) {
            console.log(error);
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    };

    return (
        <>
            {!showConfirmPhone ? (
                <>
                    <form onSubmit={formik.handleSubmit}>
                        <div>
                            <h5>Espace {userType === "individual" ? "Particuliers" : "Professionnels"}</h5>
                            <h2>Inscription</h2>
                            {errorMessage && <Alert type="error">{errorMessage}</Alert>}
                            <Input
                                required={true}
                                type="text"
                                id="firstname"
                                name="firstname"
                                placeholder="Votre prénom"
                                onChange={formik.handleChange}
                                value={formik.values.firstname}
                                onBlur={formik.handleBlur}
                                errorMessage={formik.touched.firstname && formik.errors.firstname ? formik.errors.firstname : ""}
                            />
                            <Input
                                required={true}
                                type="text"
                                id="lastname"
                                name="lastname"
                                placeholder="Votre nom"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastname}
                                errorMessage={formik.touched.lastname && formik.errors.lastname ? formik.errors.lastname : ""}
                            />
                            <Input
                                required={true}
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                errorMessage={formik.touched.email && formik.errors.email ? formik.errors.email : ""}
                            />
                            <Input
                                required={true}
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Mot de passe"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                errorMessage={formik.touched.password && formik.errors.password ? formik.errors.password : ""}
                            />
                            <Input
                                required={true}
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                placeholder="Confirmez votre mot de passe"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.confirmPassword}
                                errorMessage={
                                    formik.touched.confirmPassword && formik.errors.confirmPassword ? formik.errors.confirmPassword : ""
                                }
                            />
                            <div className="d-flex gap-1 align-items-end">
                                <IndicatifPhone
                                    setPhonePrefix={setPhonePrefix}
                                    value={phonePrefix}
                                />
                                <Input
                                    required={true}
                                    type="tel"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    placeholder="Téléphone"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.phoneNumber}
                                    errorMessage={formik.touched.phoneNumber && formik.errors.phoneNumber ? formik.errors.phoneNumber : ""}
                                />
                            </div>
                            {userType === "professionnels" && (
                                <Input
                                    required={true}
                                    type="text"
                                    id="companyName"
                                    name="companyName"
                                    placeholder="Nom de l'entreprise"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.companyName}
                                    errorMessage={formik.touched.companyName && formik.errors.companyName ? formik.errors.companyName : ""}
                                />
                            )}
                            <a
                                href="https://idprotect.fr/politique-de-confidentialites/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="mb-1 d-block">
                                Conditions Générales d'Utilisation
                            </a>
                            <Checkbox
                                label="J'accepte les Conditions Générales d'Utilisation"
                                onChange={() => setIsTermsAccepted(!isTermsAccepted)}
                                checked={isTermsAccepted}
                            />
                            <div
                                className="mb-1 recaptcha-resend"
                                id="recaptcha-container"></div>
                        </div>
                        <div className="mt-auto">
                            {isLoading && <Loader text=""></Loader>}
                            <Button
                                label="S'inscrire"
                                className="m-auto mb-1"
                                buttonType="submit"
                                disabled={!isTermsAccepted}
                            />
                            <Button
                                label="Je suis déjà inscrit"
                                buttonType="button"
                                onClick={() => navigate(`/${userType}/login`)}
                                className="m-auto"
                                type={Button_Type.LABEL}
                            />
                        </div>
                    </form>
                </>
            ) : (
                <ConfirmPhone
                    ConfirmationResult={final}
                    phoneNumber={getFullPhoneNumber(formik.values.phoneNumber)}
                    showConfirmPhone={() => setshowConfirmPhone(false)}
                    showConfirmEmail={showConfirmEmail}
                    setConfirmEmailMessage={setConfirmEmailMessage}
                    RegistrationData={RegistrationData}
                />
            )}
        </>
    );
};

export default RegisterForm;
