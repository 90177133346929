import './style.scss';

import React, { FC, useState } from 'react';

import { ReactComponent as IconCarretDown } from '../../assets/img/icons/icon_carret-Down.svg';

type AccordionProps = {
	title: string;
	children: React.ReactNode;
};

const Accordion: FC<AccordionProps> = ({ title, children }) => {
	const [expanded, setExpanded] = useState(false);

	const handleToggle = () => {
		setExpanded(!expanded);
	};

	return (
		<div className="accordion">
			<div className={`accordion__header ${expanded ? 'expanded' : ''}`} onClick={handleToggle}>
				<p className="accordion__title">{title}</p>
				<div className={`accordion__icon ${expanded ? 'expanded' : ''}`}>
					<IconCarretDown fill='#1956A8' width={14} />

				</div>
			</div>
			{expanded && <div className="accordion__content">{children}</div>}
		</div>
	);
};

export default Accordion;
