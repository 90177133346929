import './style.scss';

import { Button_Style, Button_Type } from '../Button/Button';
import { FC, useContext, useState } from 'react';

import Alert from '../Alert';
import Button from '../Button';
import Input from '../Input';
import Loader from '../Loader';
import Snackbar from '../Snackbar';
import { UserContext } from '../../contexts/UserContext';
import { invitation } from '../../api/invitation'

const SponsoCredits: FC = () => {
  const [email, setEmail] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useContext(UserContext)
  const userType = user?.type;
  const handleSendInvite = async () => {
    setIsLoading(true)
    try {
      await invitation(email, userType);
      setSuccessMessage('Invitation envoyée avec succès.')
      setEmail('')
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      setErrorMessage(error.response.data.error)
    }
  }

  return (
    <div className="SponsoCredits">
      <Snackbar title="Gagnez des crédits">
        <p>ID Protect a mis en place un système de parrainage qui vous permet de gagner des crédits à chaque partage. </p>
        {errorMessage && <Alert type="error">{errorMessage}</Alert>}
        {successMessage && <Alert type="success">{successMessage}</Alert>}
        {isLoading && <Loader />}
        <Input placeholder="Email" className='mb-0' onChange={(e) => setEmail(e.target.value)} type="email" value={email} />
        <Button type={Button_Type.PRIMARY} style={Button_Style.OUTLINED} label='Partager' className='text-center' onClick={() => handleSendInvite()} />
      </Snackbar>
    </div>
  );
}

export default SponsoCredits;
