import './style.scss';

import { FC } from 'react';

type CheckboxProps = {
  label?: string | React.ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
} & Record<string, any>;

const Checkbox: FC<CheckboxProps> = ({ label, checked, onChange, className, ...rest }) => {

  return (
    <label className={`checkbox ${className}`}>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className="checkbox__checkmark"></span>
      {label && <span className="checkbox__label">{label}</span>}
    </label>
  );
};

export default Checkbox;
