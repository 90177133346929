import React, { FC, useContext, useState } from "react";

import DocumentCard from "../components/DocumentCard";
import { DocumentUnion } from "../types/types";
import { DocumentsContext } from "../contexts/DocumentsContext";
import EmptyContent from "../components/EmptyContent";
import { FolderContext } from "../contexts/FoldersContext";
import FoldersList from "../components/FoldersList";
import HeaderSection from "../components/HeaderSection";
import NotificationsList from "../components/NotificationsList";
import PageHeader from "../components/PageHeader";
import Table from "../components/Table";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const columns = [
    { key: "nationality", label: "" },
    { key: "type", label: "Type de document" },
    { key: "number", label: "Numéro" },
    { key: "createdAt", label: "Date de l'ajout" },
    { key: "status", label: "Statut" },
    { key: "isBlocked", label: "" },
];

const Dashboard: FC = () => {
    const [selectedRows, setSelectedRows] = useState<DocumentUnion[]>([]);
    const { allDocuments } = useContext(DocumentsContext);
    const { folders, sharedFolders } = useContext(FolderContext);

    const handleSelectionChange = (rows: DocumentUnion[]) => {
        setSelectedRows(rows);
    };

    return (
        <>
            <PageHeader>
                <h5>Tableau de bord</h5>
            </PageHeader>
            <div className="Dashboard content__body">
                <div className="row">
                    <div className="col-xl-9">
                        <div className="d-flex flex-column h-100">
                            <HeaderSection
                                title="Pièces d'identité"
                                number={allDocuments.length}
                            />
                            {isMobile ? (
                                <>
                                    {allDocuments.map((document: DocumentUnion) => (
                                        <React.Fragment key={document.id}>
                                            <DocumentCard document={document} />
                                        </React.Fragment>
                                    ))}
                                </>
                            ) : (
                                <Table
                                    columns={columns}
                                    rows={allDocuments}
                                    onSelectionChange={handleSelectionChange}
                                    selectedRows={selectedRows}
                                    editable={false}
                                />
                            )}
                            <HeaderSection
                                title="Mes dossiers"
                                number={folders.length}
                            />
                            <FoldersList folders={folders} />
                            <div className="mb-2">
                                <HeaderSection
                                    title="Les dossiers partagés avec moi"
                                    number={sharedFolders.length}
                                />
                                {sharedFolders.length > 0 ? (
                                    <FoldersList folders={sharedFolders} />
                                ) : (
                                    <EmptyContent text="Aucun dossier partagé avec vous" />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3">
                        <NotificationsList />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
