import { FC, useContext, useState } from "react";

import AddFilterDocument from "../components/AddFilterDocument";
import Alert from "../components/Alert";
import Button from "../components/Button";
import { Button_Type } from "../components/Button/Button";
import DocumentIllustration from "../components/DocumentIllustration";
import ImageFilter from "../components/ImageFilter";
import PageHeader from "../components/PageHeader";
import { UserContext } from "../contexts/UserContext";
import watermark from "../assets/img/badgeBlue.png";
import watermarkPDF from "../assets/img/filter_pdf.png";

const FilterPage: FC = () => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [selectedFilesPDF] = useState<File[]>([]);

    const { userType } = useContext(UserContext);
    const handleChangeFiles = (files: File[]) => {
        setSelectedFiles((prevSelectedFiles) => {
            // Filter out any files that already exist in the prevSelectedFiles array
            const uniqueFiles = files.filter((file) => !prevSelectedFiles.some((prevFile) => prevFile.name === file.name));
            return [...prevSelectedFiles, ...uniqueFiles];
        });
    };

    const redirectToExternal = () => {
        window.location.href = "https://app.idprotect.fr/particuliers/register";
    };

    const handleFileRemoval = (file: File) => {
        const updatedFiles = selectedFiles.filter((selectedFile) => selectedFile !== file);
        setSelectedFiles(updatedFiles);
    };

    return (
        <>
            <PageHeader>
                <h5>Ajouter un filigrane</h5>
            </PageHeader>
            <div className="VerifyPage content__body">
                {/* <h2 className="VerifyPage__title">Ajouter un filigrane</h2> */}
                <div className="VerifyPage__row row">
                    <div className="VerifyPage__content col-sm-5">
                        <div className="addDocumentForm card">
                            <div className="addDocumentForm__buttons">
                                <h3>Protégez vos documents</h3>
                                <p className="mb-1">Apposez un filigrane ID Protect avant d'envoyer vos documents.</p>
                                <Alert type="info">ID Protect ne conserve aucune copie du document.</Alert>
                                <AddFilterDocument
                                    title=""
                                    onChangeFiles={handleChangeFiles}
                                    onDelete={handleFileRemoval}
                                />
                            </div>
                            {!userType && (
                                <div className="card card__promo">
                                    <h3>Restez informé quand votre document est présenté</h3>
                                    <p className="mb-2">
                                        ID PROTECT vous informe à chaque fois que votre document est présenté chez ses partenaires : entrée
                                        en relation, ouverture de compte bancaire, demande de crédit …
                                    </p>
                                    <Button
                                        label="Créer un compte"
                                        type={Button_Type.WHITE}
                                        onClick={redirectToExternal}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-7 p-1">
                        {selectedFiles.length > 0 || selectedFilesPDF.length > 0 ? (
                            <div className="row center">
                                {selectedFiles.map((file, index) => (
                                    <div
                                        className="col-4"
                                        key={file.name}>
                                        <ImageFilter
                                            imageSrc={file}
                                            watermarkSrc={watermark} // filigrane pour les images
                                            watermarkPDF={watermarkPDF} // filigrane pour les PDFs
                                        />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <DocumentIllustration />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FilterPage;
