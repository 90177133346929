import './style.scss';

import React, { FC, ReactNode, useState } from 'react';

import { ReactComponent as IconCloset } from '../../assets/img/icons/icon_Close.svg';

const SidebarSlide = ({ children, onClose }: { children: ReactNode, onClose: () => void }) => {
  // close the sidebar when clicking outside SidebarSlide__content
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const handleClickOutside = (event: any) => {

    if (event.target.className.length > 0 && event.target.className.includes('SidebarSlide--open')) {
      setSidebarOpen(false);
      onClose();
    }
  };

  return (
    <div className={`SidebarSlide ${sidebarOpen ? 'SidebarSlide--open' : ''}`} onClick={handleClickOutside}>
      <div className="SidebarSlide__content">
        <div className="SidebarSlide__content__close" onClick={() => onClose()}>
          <IconCloset width={20} fill='#1956A8' />
        </div>
        {children}
      </div>
    </div>
  );
}

export default SidebarSlide;
