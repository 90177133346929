import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AccompaniementsContext } from "../contexts/AccompaniementsContext";
import { AccompanimentType } from "../types/types";
import DocsPerOrganisms from "../components/DocsPerOrganisms";
import { DocumentUnion } from "../types/types";
import { DocumentsContext } from "../contexts/DocumentsContext";
import File from "../components/File";
import FileUploader from "../components/FileUploader";
import HeaderSection from "../components/HeaderSection";
import { ReactComponent as IconReturn } from "../assets/img/icons/icon_arrow-Left.svg";
import PageHeader from "../components/PageHeader";
import StatusAccompagnement from "../components/StatusAccompagnement";
import Table from "../components/Table";
import { UserContext } from "../contexts/UserContext";
import { addDocumentToAccompaniment } from "../api/accompaniment";

const columns = [
    { key: "nationality", label: "" },
    { key: "type", label: "Type de document" },
    { key: "number", label: "Numéro" },
    { key: "createdAt", label: "Date de l'ajout" },
    { key: "status", label: "Statut" },
    { key: "isBlocked", label: "" },
];

const IDAssistantDetail = () => {
    const [selectedRows, setSelectedRows] = useState<DocumentUnion[]>([]);
    const { accompaniments } = useContext(AccompaniementsContext);
    const { user } = useContext(UserContext);
    const [currentAccompaniment, setCurrentAccompaniment] = useState<AccompanimentType>();
    const { allDocuments } = useContext(DocumentsContext);
    const type = user?.type;
    const navigate = useNavigate();
    let { id } = useParams();
    const handleReturn = () => {
        navigate(-1);
    };

    const fileUploaderProps = {
        className: "mb-0",
        title: "Ajouter un procès verbal de perte ou de vol",
        allowedFormats: "application/pdf, image/jpeg, image/png",
        onDelete: () => console.log("delete"),
        onChange: (files: any) => handleAddDocument(files),
        isList: false,
    };

    useEffect(() => {
        if (!id || !accompaniments) return;
        console.log(id);
        setCurrentAccompaniment(accompaniments.find((item) => item?.id?.toString() === id));
    }, [id, accompaniments]);

    const handleSelectionChange = (rows: DocumentUnion[]) => {
        setSelectedRows(rows);
    };

    const handleAddDocument = async (files: any) => {
        try {
            const response = await addDocumentToAccompaniment(type, files[0]);
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    };

    console.log(currentAccompaniment);
    return (
        <>
            <PageHeader>
                <h5>ID Assistant/{id}</h5>
            </PageHeader>
            <div className="content__body">
                <div className="row">
                    <div className="col-lg-9">
                        <IconReturn
                            fill="#5E89C2"
                            width="28px"
                            onClick={handleReturn}
                            className="pointer"
                        />
                        {currentAccompaniment?.status && <StatusAccompagnement status={currentAccompaniment?.status} />}
                        <HeaderSection
                            title="Documents perdus/volés"
                            number=""
                        />
                        <Table
                            columns={columns}
                            rows={allDocuments}
                            onSelectionChange={handleSelectionChange}
                            selectedRows={selectedRows}
                            editable={false}
                        />

                        <DocsPerOrganisms organisms={currentAccompaniment?.accompaniementContacts} />
                        <HeaderSection
                            title="Ma déclaration de perte ou de vol"
                            number="1"
                        />
                        {currentAccompaniment?.accompanimentClientDocuments &&
                        currentAccompaniment?.accompanimentClientDocuments.length > 0 ? (
                            <File file={currentAccompaniment?.accompanimentClientDocuments[0]} />
                        ) : (
                            <FileUploader {...fileUploaderProps} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default IDAssistantDetail;
