import './style.scss';

import React, { FC, useEffect, useState } from 'react';

type ToastProps = {
  type: 'error' | 'success' | 'warning' | 'info';
  message: string;
  duration?: number; // Duration in milliseconds, default is 3000ms
  onClose?: () => void; // Function to execute when the toast is closed
};

const Toast: FC<ToastProps> = ({ message, duration = 4000, onClose, type }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      onClose && onClose();
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [duration, onClose]);

  const handleToastClick = () => {
    setVisible(false);
    onClose && onClose();
  };

  return (
    <div className={`Toast ${visible ? 'show' : 'hide'} Toast-${type}`} onClick={handleToastClick}>
      <p>{message}</p>
    </div>
  );
};

export default Toast;
