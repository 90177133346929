import "./style.scss";

import Alert from "../Alert";
import { FC } from "react";
import FileUploader from "../FileUploader";

type DocumentUploadProps = {
    type: string;
    setSelectedFiles: any;
    selectedFiles: any;
};

const DocumentUpload: FC<DocumentUploadProps> = ({ type, setSelectedFiles, selectedFiles }) => {
    const handleChangeFiles = (files: any) => {
        setSelectedFiles((prevSelectedFiles: any) => [...prevSelectedFiles, ...files]);
    };

    const handleFileRemoval = (file: File) => {
        const updatedFiles = selectedFiles.filter((selectedFile: any) => selectedFile !== file);
        setSelectedFiles(updatedFiles);
    };
    return (
        <div className="DocumentUpload">
            {type === "Passeport" ? (
                <FileUploader
                    onChange={handleChangeFiles}
                    onDelete={handleFileRemoval}
                    title="Recto"
                    fileLimit={1}
                    maxFileSize={12}
                    allowedFormats={"image/jpeg, image/png, application/pdf"}
                />
            ) : (
                <>
                    <Alert type="info">Un seul fichier est nécessaire si le recto/verso figure sur la même page.</Alert>
                    <FileUploader
                        onChange={handleChangeFiles}
                        onDelete={handleFileRemoval}
                        title="Recto/Verso"
                        fileLimit={1}
                        maxFileSize={12}
                        allowedFormats={"image/jpeg, image/png, application/pdf"}
                    />
                    <FileUploader
                        onChange={handleChangeFiles}
                        onDelete={handleFileRemoval}
                        title="Verso"
                        fileLimit={1}
                        maxFileSize={12}
                        allowedFormats={"image/jpeg, image/png, application/pdf"}
                    />
                </>
            )}
        </div>
    );
};

export default DocumentUpload;
