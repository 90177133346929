import './style.scss';

import React, { FC, useState } from 'react';

import { ReactComponent as Empty } from "../../assets/img/icons/icon_empty.svg";

type EmptyContentProps = {
  text: string
}
const EmptyContent: FC<EmptyContentProps> = ({ text }) => {
  return (
    <div className="EmptyContent">
      <Empty />
      <p>{text ? text : "Aucun résultat trouvé"}</p>
    </div>
  );
}

export default EmptyContent;
