import "./style.scss";

import React, { FC, useState } from "react";

import File from "../File";
import { ReactComponent as IconDelete } from "../../assets/img/icons/icon_Trash.svg";
import { ReactComponent as IconFile } from "../../assets/img/icons/icon_File.svg";
import { ReactComponent as IconUpload } from "../../assets/img/icons/icon_Upload.svg";
import { file } from "jszip";

interface FileUploaderProps {
    title?: string;
    onChange: (files: File[]) => void;
    fileLimit?: number;
    allowedFormats?: string;
    maxFileSize?: number;
    onDelete: (files: File) => void;
    className?: string;
    isList?: boolean;
}

const FileUploader: FC<FileUploaderProps> = ({
    title,
    onChange,
    fileLimit,
    allowedFormats,
    maxFileSize,
    onDelete,
    className,
    isList = true,
}) => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [error, setError] = useState<string>("");

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        handleFileSelection(files);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        handleFileSelection(files);
    };

    const formatFileSize = (size: number) => {
        if (size === 0) {
            return "0 B";
        }
        const units = ["B", "KB", "MB", "GB", "TB"];
        const digitGroups = Math.floor(Math.log10(size) / Math.log10(1024));
        const formattedSize = (size / Math.pow(1024, digitGroups)).toFixed(2);
        return `${formattedSize} ${units[digitGroups]}`;
    };

    const handleFileSelection = (files: FileList | null) => {
        if (files && files.length > 0) {
            const fileList = Array.from(files);
            const selectedFilesCount = selectedFiles.length + fileList.length;
            if (fileLimit && selectedFilesCount > fileLimit) {
                setError("Nombre de fichiers dépassé");
                return;
            }

            const invalidFiles = fileList.filter((file) => {
                if (allowedFormats && !allowedFormats.includes(file.type)) {
                    setError("Format de fichier non autorisé");
                    return true;
                }
                if (maxFileSize && file.size > maxFileSize * 1024 * 1024) {
                    setError(`Fichier limité à ${maxFileSize} Mo`);
                    return true;
                }
                return false;
            });
            if (invalidFiles.length > 0) {
                return;
            }
            const updatedFiles = [...selectedFiles, ...fileList];
            // Remove duplicates
            const uniqueFiles = updatedFiles.filter((file, index, self) => index === self.findIndex((f) => f.name === file.name));
            setError("");
            isList ? setSelectedFiles(uniqueFiles) : setSelectedFiles([]);
            onChange(uniqueFiles);
        }
    };

    const handleFileRemoval = (file: File) => {
        const updatedFiles = selectedFiles.filter((selectedFile) => selectedFile !== file);
        setSelectedFiles(updatedFiles);
        onDelete(file);
    };

    return (
        <>
            {selectedFiles.length > 0 ? (
                <div className={!isList ? "d-flex gap-1 flex-reverse" : ""}>
                    {selectedFiles.map((file, key) => (
                        <div key={file.name}>
                            {isList ? (
                                <div className={`FileUploaded  ${className}`}>
                                    <IconFile
                                        fill="#454980"
                                        width={20}
                                        height={20}
                                    />
                                    <p className="FileUploaded__name">
                                        {file.name} ({formatFileSize(file.size)})
                                    </p>
                                    <IconDelete
                                        fill="#FF5326"
                                        width={20}
                                        height={20}
                                        className="iconDelete"
                                        onClick={() => handleFileRemoval(file)}
                                    />
                                </div>
                            ) : (
                                <></>
                                // Remove File beecause I have reload the list to add the new document
                                // Temporary solution I found uncomment
                                // Bug is when you want to delete file, we don't have info of the file
                                // <File file={{ name: file.name, path: URL.createObjectURL(file), receivedAt: "" }} />
                            )}
                        </div>
                    ))}
                    {!fileLimit && (
                        <>
                            <div
                                className={`FileUploader  ${className}`}
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}>
                                <IconUpload fill="#1956A8" />
                                <p>{title ? title : "Télécharger"}</p>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    multiple
                                />
                            </div>
                            {error && <p className="error">{error}</p>}
                        </>
                    )}
                </div>
            ) : (
                <>
                    <div
                        className={`FileUploader ${className}`}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}>
                        <IconUpload fill="#1956A8" />
                        <p>{title ? title : "Télécharger"}</p>
                        <input
                            type="file"
                            accept={allowedFormats ? allowedFormats : "image/* , application/pdf"}
                            onChange={handleImageUpload}
                            multiple
                        />
                    </div>
                    {error && <p className="error">{error}</p>}
                </>
            )}
        </>
    );
};

export default FileUploader;
