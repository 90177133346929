import "./style.scss";

import { FC, useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import { UserContext } from "../../contexts/UserContext";
import links from "./links";

type MenuItemsProps = {
    isCollapsed: boolean;
};
const MenuItems: FC<MenuItemsProps> = ({ isCollapsed }) => {
    const { userType, isVerified } = useContext(UserContext);

    const urlType = userType === "partner" ? "/professionnels/" : "/particuliers/";
    const location = useLocation();

    const filteredLinks = links.filter((link) => {
        // hide the menu item if the user is not verified and the link.hideIfNotVerified is true
        if (link?.hideIfUnverified) {
            return isVerified;
        }
        // hide the menu item if the user is a partner and the link.hideInPartner is true
        if (link?.hideInPartner) {
            return userType !== "partner";
        }
        return true;
    });

    return (
        <>
            <ul>
                {filteredLinks.map((link, index) => (
                    <li
                        key={index}
                        className={location.pathname === `${urlType}${link.url}` ? "active" : ""}>
                        <Link
                            to={urlType + link.url}
                            className="aside__button">
                            <div className="aside__item">
                                {link.icon}
                                {!isCollapsed && <span>{link.label}</span>}
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default MenuItems;
