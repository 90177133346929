import { useEffect, useState } from "react";

import Alert from "../components/Alert";
import { documentConfirm } from "../api/invitation";
import svgImage from "../assets/img/Illustration_documents.svg";
import { useLocation } from "react-router-dom";

export default function IDTrackerRequest() {
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const requestId = searchParams.get("requestId");
    const status = searchParams.get("status");

    useEffect(() => {
        handleConfirm();
    }, []);

    const handleConfirm = async () => {
        try {
            const response = await documentConfirm(requestId, status);
            setSuccessMessage(
                response.data.status === "confirm"
                    ? "Vous avez validé l'utilisation de votre document d'identité."
                    : "Vous avez refusé l'utilisation de votre document d'identité.",
            );
        } catch (error: any) {
            setErrorMessage(error.response.data.error);
        }
    };
    return (
        <main className="login">
            <div className="login__container">
                <div className="login__form">
                    <h5>Espace Particulier</h5>
                    <h2>ID Tracker</h2>
                    {errorMessage && <Alert type="error">{errorMessage}</Alert>}
                    {successMessage && <Alert type="success">{successMessage}</Alert>}
                </div>
                <div className="login__img">
                    <img
                        src={svgImage}
                        className="illustration-document"
                        alt="Login"
                    />
                </div>
            </div>
        </main>
    );
}
