import "./style.scss";

import React, {FC, useState} from "react";
import {Pricing} from "../../constants/pricings";
import MoneyDisplay from "../MoneyDisplay";
import Modal from "../Modal";
import PriceCardDetail from "../PriceCardDetail";
import {Link} from "react-router-dom";

type PriceCardProps = {
    title: string;
    logo: string;
    price?: string;
    subtitle?: string;
    children: React.ReactNode;
    monthly?: boolean;
    plan?: Pricing | null
    isMonthActive?: boolean,
    setIsMonthActive?: (isMonthActive: boolean) => void;
};
const PriceCard: FC<PriceCardProps> = (
    { title, logo, price, subtitle,
        children, monthly, plan,
        isMonthActive, setIsMonthActive
    }) => {

    const [showModal, setShowModal] = useState<boolean>(false);

    const saveMoneyValue = plan?.priceYear ? (plan?.price * 12 - plan?.priceYear!).toFixed(2) : '0'

    return (
        <>
            <div className="PriceCard">
                <div className="PriceCard__header">
                    <div className="pl">
                        <h2>{title}</h2>
                        <img
                            src={logo}
                            alt={title}
                        />
                    </div>
                    <div className="pl">
                        {price && (
                            <div>
                                <h3 className={monthly ? "mb-0" : ""}>{price}</h3>
                                {monthly && <p>/mois</p>}
                            </div>
                        )}
                        {plan && (
                            <MoneyDisplay plan={plan} isMonth={isMonthActive}/>
                        )}
                    </div>

                    {plan?.priceYear! > 0 && (
                        <div className="text">
                            {isMonthActive && (
                                <div>
                                    {parseInt(saveMoneyValue) > 0 && (
                                        <div>
                                            Économisez <b className="save-money">{saveMoneyValue} €</b>
                                            sur l’abonnement en choisissant un paiement annuel.
                                        </div>
                                    )}
                                    {parseInt(saveMoneyValue) <= 0 && (
                                        <div>
                                            Paiement annuel.
                                        </div>
                                    )}
                                </div>
                            )}
                            {!isMonthActive && (
                                <div>
                                    Revenir à l'abonnement mensuel
                                </div>
                            )}

                            <div className="toggle" onClick={() => {
                                if (setIsMonthActive) {
                                    setIsMonthActive(!isMonthActive)
                                }
                            }}>
                                <u>Choisir</u>
                            </div>

                        </div>

                    )}
                    <h4>{subtitle}</h4>
                </div>
                <div className="PriceCard__body">{children}</div>
                <br/>
                {plan && (
                    <span className="savoir-plus">
                         <Link to={'#'} className="link" onClick={() => {
                             setShowModal(true);
                         }}>
                            <u>en savoir plus</u>
                        </Link>
                    </span>
                )}
            </div>
            {showModal &&
                <Modal
                    onClose={() => setShowModal(false)} isOpen={showModal} title={plan?.head} infoModal={true}
                    type={"info"}>
                    <PriceCardDetail plan={plan} features={plan?.features} isMonth={isMonthActive}/>
                </Modal>
            }
        </>
    );
};

export default PriceCard;
