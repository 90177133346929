import "./style.scss";

import { FC, ReactNode, useEffect } from "react";

import Aside from "../Aside/Aside";
import { useLocation } from "react-router-dom";

type LayoutProps = {
    children: ReactNode;
};

const Layout: FC<LayoutProps> = ({ children }) => {
    const location = useLocation();
    useEffect(() => {
        // Check if the URL contains "professionnels"
        const hasProfessionnels = location.pathname.includes("professionnels");

        // Apply a class to the body element
        if (hasProfessionnels) {
            document.body.classList.add("professionnels");
        } else {
            document.body.classList.remove("professionnels");
        }
    }, [location]);

    return (
        <>
            <main>
                <Aside />
                <div className="content">{children}</div>
            </main>
        </>
    );
};

export default Layout;
