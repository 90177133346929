import "./style.scss";

import Checkbox from "../Checkbox";
import HeaderSection from "../HeaderSection";
import { ReactComponent as IconArrowRight } from "../../assets/img/icons/icon_arrow-Right.svg";
import { ReactComponent as IconCheck } from "../../assets/img/icons/icon_Check.svg";
import { ReactComponent as IconInfo } from "../../assets/img/icons/icon_Info.svg";
import SidebarSlide from "../SidebarSlide";
import { useState } from "react";

const StatusAccompagnement = ({ status }: { status: string }) => {
    const [slidebarIsOpen, setSlidebarIsOpen] = useState(false);
    const getPercents = () => {
        switch (status) {
            case "Traitement du dossier":
                return "35%";
            case "Courriers envoyés":
                return "70%";
            case "ID Protect a reçu les AR":
                return "100%";
            default:
                return 0;
        }
    };
    const close = () => {
        setSlidebarIsOpen(false);
    };
    return (
        <>
            <HeaderSection
                title="Statut de la demande"
                number={getPercents()}
            />
            <div className="StatusAccompagnement">
                <div className="StatusAccompagnement__step StatusAccompagnement__step__success">
                    <div
                        className="pointer"
                        onClick={() => setSlidebarIsOpen(true)}>
                        <IconInfo
                            fill="#12BC61"
                            width={16}
                        />
                    </div>
                    <span>Traitement du dossier</span>
                    <IconCheck
                        fill="#12BC61"
                        width={14}
                    />
                </div>
                <IconArrowRight
                    fill="#5E89C2"
                    width={20}
                />
                <div className="StatusAccompagnement__step">
                    <span>Courriers envoyés ...</span>
                </div>
                <IconArrowRight
                    fill="#A3BBDC"
                    width={20}
                />
                <div className="StatusAccompagnement__step StatusAccompagnement__step__disabled">
                    <span>ID Protect a reçu les AR</span>
                </div>
            </div>
            {slidebarIsOpen && (
                <SidebarSlide onClose={() => close()}>
                    <h3>Checklist</h3>
                    <p className="mb-2">Voici quelques préconisations pour vous aider à protéger votre identité.</p>
                    <Checkbox label="Déclarer la perte au commisariat" />
                    <Checkbox label="Vérifier ses comptes banquaires" />
                    <Checkbox label="Renouvellement des pièces d'identité" />
                    <Checkbox
                        label={
                            <a
                                rel="noreferrer"
                                href="https://www.service-public.fr/particuliers/vosdroits/F17608"
                                target="_blank">
                                Consulter le fichier FICP et usurpation de la banque de France
                            </a>
                        }
                    />
                    <Checkbox
                        label={
                            <a
                                rel="noreferrer"
                                href="https://www.cnil.fr/fr/demander-une-verification-sur-le-ficoba"
                                target="_blank">
                                Consulter le fichier Ficoba de la CNIL
                            </a>
                        }
                    />
                </SidebarSlide>
            )}
        </>
    );
};

export default StatusAccompagnement;
