import "./assets/scss/style.scss";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useContext, useEffect } from "react";

import CommonsConfirmation from "./pages/CommonsConfirmation";
import ConditionalLayout from "./ConditionalLayout";
import ConfirmPhone from "./components/ConfirmPhone/ConfirmPhone";
import FilterPage from "./pages/FilterPage";
import ForgetPassword from "./pages/ForgetPassword";
import Home from "./components/Home";
import IDTrackerRequest from "./pages/IDTrackerRequest";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import { UserContext } from "./contexts/UserContext";
import { Worker } from "@react-pdf-viewer/core";

function App() {
    const { userType } = useContext(UserContext);

    useEffect(() => {
        document.body.classList.add(userType ? userType : "individual");
    }, [userType]);

    useEffect(() => {
        let scrollTimeout: NodeJS.Timeout;
        const handleScroll = () => {
            document.body.classList.add("active");
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(() => {
                document.body.classList.remove("active");
            }, 1000);
        };
        window.addEventListener("scroll", handleScroll, true);
        return () => {
            window.removeEventListener("scroll", handleScroll, true);
        };
    }, []);

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <div>
                <BrowserRouter>
                    <Routes>
                        <Route
                            path="/"
                            element={<Home />}
                        />
                        <Route
                            path="/particuliers/register"
                            element={<Register />}
                        />
                        <Route
                            path="/particuliers/login"
                            element={<Login />}
                        />
                        <Route
                            path="/professionnels/login"
                            element={<Login />}
                        />
                        <Route
                            path="/professionnels/register"
                            element={<Register />}
                        />
                        <Route
                            path={`/commons/confirmation`}
                            element={<CommonsConfirmation />}
                        />
                        <Route
                            path={`/commons/password`}
                            element={<ResetPasswordPage />}
                        />
                        <Route
                            path="/particuliers/request"
                            element={<IDTrackerRequest />}
                        />
                        <Route
                            path="/filter"
                            element={
                                <main>
                                    <div className="content">
                                        <FilterPage />
                                    </div>
                                </main>
                            }
                        />
                        <Route
                            path="/confirmPhone"
                            element={<ConfirmPhone />}
                        />
                        <Route
                            path="/resend"
                            element={<ForgetPassword />}
                        />
                        <Route
                            path="/*"
                            element={<ConditionalLayout />}
                        />
                    </Routes>
                </BrowserRouter>
            </div>
        </Worker>
    );
}

export default App;
