export function formatTypeDocument(typeDocument){
	  const types = {
	'id': 'Carte d\'identité',
	'id_card': 'Carte d\'identité',
	'passeport': 'Passeport',
	'passport': 'Passeport',
	'permis': 'Permis de conduire',
	'driver_permit': 'Permis de conduire',
	'residency_permit': 'Titre de séjour',
	'sejour': 'Titre de séjour',
  }
  return types[typeDocument]
}
