import "./style.scss";

import { FC } from "react";
import { Pricing } from "../../constants/pricings";

interface MoneyDisplayProps {
    plan?: Pricing | null;
    isMonth?: boolean;
}

const MoneyDisplay: FC<MoneyDisplayProps> = ({ plan, isMonth }) => {
    let first: string | undefined = plan?.price.toFixed(2).split(".")[0];
    let cent: string | undefined = plan?.price.toFixed(2).split(".")[1];

    if (isMonth !== undefined) {
        if (!isMonth) {
            first = plan?.priceYear?.toFixed(2).split(".")[0];
            cent = plan?.priceYear?.toFixed(2).split(".")[1];
        }
    }

    return (
        <div className="MoneyDisplay d-flex justify-content-start align-items-center">
            <div className="number">{first}</div>
            <div className="">
                <div className="devise">€{cent !== "00" && <span>{cent}</span>}</div>
                <div className="description">{isMonth === undefined ? plan?.description : `${isMonth === true ? "/mois" : "/an"}`}</div>
            </div>
        </div>
    );
};

export default MoneyDisplay;
