import "./style.scss";

import { Button_Style, Button_Type } from '../Button/Button';
import { DocumentBasylic, DocumentUnion } from "../../types/types";
import { FC, useEffect, useState } from "react";

import Button from "../Button";
import DeleteDocumentModal from "../DeleteDocumentModal/DeleteDocumentModal";
import LockDocumentModal from "../LockDocumentModal";
import LostDocumentModal from "../LostDocumentModal";
import { useNavigate } from "react-router-dom";

type SelectedRowsActionsProps = {
  isSelected: boolean;
  documents: DocumentUnion[];
  onSelectionChange?: (selectedRows: DocumentUnion[]) => void;
};
const SelectedRowsActions: FC<SelectedRowsActionsProps> = ({
  isSelected,
  documents,
  onSelectionChange
}) => {
  const [showModalLock, setShowModalLock] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalLost, setShowModalLost] = useState(false);

  const navigate = useNavigate();
  const [documentsLocked, setDocumentsLocked] = useState(false);
  const [documentsLost, setDocumentsLost] = useState(false);
  useEffect(() => {
    // Check if all documents are locked
    const allLocked = isSelected
      ? documents.every((document: DocumentUnion) => document.locked === true)
      : false;

    // Set documentsLocked to true only if all documents are locked
    setDocumentsLocked(allLocked);

    const allLost = isSelected ? documents.every((document: DocumentUnion) => document.lost === true) : false;
    setDocumentsLost(allLost);
  }, [documents, documentsLocked]);

  const handleAddClick = () => {
    navigate("/particuliers/idtracker");
  };


  return (
    <div className="d-flex gap-05 mb-1">
      <Button
        label="Ajouter"
        size="M"
        disabled={isSelected}
        onClick={handleAddClick}
      />
      {(!documentsLost && isSelected) && (
        <>
          <Button
            label={"Déclarer perdu"}
            style={Button_Style.OUTLINED}
            size="M"
            disabled={documents.length > 1}
            onClick={() => setShowModalLost(true)}
          />

          <Button
            label={!documentsLocked ? "Vérouiller" : "Déverrouiller"}
            size="M"
            onClick={() => setShowModalLock(true)}
          />
        </>
      )}

      {isSelected && (
        <Button
          label="supprimer"
          type={Button_Type.Error}
          size="M"
          onClick={() => setShowModalDelete(true)}
        />
      )}

      <LockDocumentModal
        isOpen={showModalLock}
        onClose={() => setShowModalLock(false)}
        documents={documents}
        documentsLocked={documentsLocked}
        onSubmit={onSelectionChange}
      />

      <DeleteDocumentModal
        isOpen={showModalDelete}
        onClose={() => setShowModalDelete(false)}
        documents={documents}
        onSubmit={onSelectionChange}
      />

      {showModalLost && (
        <LostDocumentModal
          isOpen={showModalLost}
          onClose={() => setShowModalLost(false)}
          documents={documents}
        />
      )}
    </div>
  );
};

export default SelectedRowsActions;
