import "./style.scss";

import React, { FC, useContext, useState } from "react";

import Alert from "../Alert";
import Button from "../Button";
import { Button_Style } from "../../components/Button/Button";
import { DocumentUnion } from "../../types/types";
import { DocumentsContext } from "../../contexts/DocumentsContext";
import Table from "../Table";

const columns = [
    { key: "nationality", label: "" },
    { key: "type", label: "Type de document" },
    { key: "number", label: "Numéro" },
    { key: "createdAt", label: "Date de l'ajout" },
    { key: "status", label: "Statut" },
    { key: "isBlocked", label: "" },
];
interface IDAssistantSelectDocumentProps {
    steps: any;
    setDatas: (data: any) => void;
    datas: any;
    handlePreviousStep: () => void;
    handleNextStep: () => void;
}

const IDAssistantSelectDocument: FC<IDAssistantSelectDocumentProps> = ({ setDatas, datas, steps, handlePreviousStep, handleNextStep }) => {
    const [selectedRows, setSelectedRows] = useState<DocumentUnion[]>([]);
    const { allDocuments } = useContext(DocumentsContext);
    const handleSelectionChange = (rows: DocumentUnion[]) => {
        const rowsIds = rows.map((row) => row.id);
        setSelectedRows(rows);
        setDatas({ ...datas, documents: rowsIds });
    };
    return (
        <div className="IDAssistantSelectDocument w-100 h-100 d-flex flex-column align-items-center justify-content-between">
            <div className="w-100">
                <h3>Vos documents d'identités</h3>
                <p className="mb-2">
                    Vous pouvez ajouter plusieurs documents à votre déclaration de perte ou de vol. Sélectionnez les depuis votre espace ID
                    Protect ou ajoutez un nouveau document.
                </p>
                <Alert type="warning">Vos documents d'identités seront mis en opposition. Cette action est irréversible.</Alert>
                <div className="IDAssistantSelectDocument__table">
                    <Table
                        className="mb-0"
                        columns={columns}
                        rows={allDocuments}
                        onSelectionChange={handleSelectionChange}
                        selectedRows={selectedRows}
                        size="S"
                        selectable
                    />
                </div>
            </div>
            <div className={`d-flex justify-content-${!steps[0].isCurrent ? "between" : "end"} w-100`}>
                {!steps[0].isCurrent && (
                    <Button
                        label={"Retour"}
                        onClick={() => handlePreviousStep()}
                        style={Button_Style.OUTLINED}
                    />
                )}
                <Button
                    disabled={datas?.documents.length === 0}
                    label={"Suivant"}
                    onClick={() => handleNextStep()}
                />
            </div>
        </div>
    );
};

export default IDAssistantSelectDocument;
