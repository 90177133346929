import axios from '../../axiosConfig';

export async function getVerifationToken(type) {
		const url = '/client/getShareIdToken';
		const data = new FormData();
		data.append('type',type);
		return axios.post(url,data);
}
//sdfsdf
export async function getVerificationResult(type,uuid) {
	const url = 'client/getDataByUUID';
	const data = new FormData();
	data.append('type',type);
	data.append('uuid',uuid);

	return axios.post(url,data);
}

//
export async function getUserCurrentVerifications(type) {
    try {

        const url = `client/ariadnext/get_analysis_history`

        const body = new FormData()
        body.append('type', type)

        const response = await axios.post(url, new FormData(), {
            // withCredentials: true,
        });

        if (response) {
            console.log(response)
        }
    } catch (error) {
        console.error(error);
    }
};

export async function getDocumentAnalysis(userType, uid) {
    try {
        const url = `client/ariadnext/get_analysis`
        const body = new FormData()
        body.append('type', userType)
        body.append('uid', uid)
        const response = await axios.post(url, new FormData(), {
            // withCredentials: true,
            data: {
                type: userType,
                uid: uid,
            },
        });

        if (response) {
            console.log(response)
        }
    } catch (error) {
        console.error(error);
    }
};


export async function getDocumentHighestAnalysisEver(userType, files) {
    const url = 'client/basylic/verify';
    const formData = new FormData();

	  formData.append('type',userType);
	  if (files.length === 1) {
		  formData.append('recto',files[0]);
	  } else {
		  formData.append('recto',files[0]);
		  formData.append('verso',files[1]);
	  }
return axios.post(url,formData);
}
export async function getDocumentHighestAnalysisEverPermis(userType, files) {
    const url = 'client/basylic/verify/permis';
    const formData = new FormData();

	  formData.append('type',userType);
	  if (files.length === 1) {
		  formData.append('recto',files[0]);
	  } else {
		  formData.append('recto',files[0]);
		  formData.append('verso',files[1]);
	  }
return axios.post(url,formData);
}

export async function getDocumentAnalysisFile(type,files) {
	const url = 'client/ariadnext/analysis';
	const formData = new FormData();
	formData.append('type',type);
	formData.append('domain','PRODUCTION')
	formData.append('frenshDocument',true)
	if (files.length === 1) {
		formData.append('recto',files[0]);
	} else {
		formData.append('recto',files[0]);
		formData.append('verso',files[1]);
	}
	return axios.post(url,formData);
}

export async function createFile(url) {
	const urlAPI = 'client/ariadnext/getFile';
	const link = urlAPI+"?path="+url
	return axios.get(link);
}

export async function getVerifyHistoric(type) {
	const url = 'client/ariadnext/historiques';
	const formData = new FormData();
	formData.append('type',type);
	return axios.post(url,formData);
}

export function extractData(userType,files) {
	const url = 'client/basylic/extract/data';
	const formData = new FormData();

	formData.append('type',userType);
	if (files.length === 1) {
		formData.append('recto',files[0]);
	} else {
		formData.append('recto',files[0]);
		formData.append('verso',files[1]);
	}
	return axios.post(url,formData);
}
