import './style.scss';

import React, { FC, useState } from 'react';

import { ReactComponent as EyeClosedIcon } from '../../assets/img/icons/icon_EyeClosed.svg';
import { ReactComponent as EyeIcon } from '../../assets/img/icons/icon_Eye.svg';
import Input from '../Input';
import Modal from '../Modal';

const ToggleIdNumber: FC<{ number: string }> = ({ number }) => {
  const [masked, setMasked] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const toggleMask = () => {
    setMasked(prevMasked => !prevMasked);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setIsPasswordValid(newPassword.length >= 6);
  };


  const maskedNumber = number ? (masked ? `${'X'.repeat(number.length - 4)}${number.slice(-4)}` : number) : "";

  if (!number) {
    return null;
  }

  const handleConfirm = async () => {
    try {
      if (isPasswordValid) {
        toggleMask();
        setShowModal(false);
      }
    } catch (error) {
      console.log(error)
    }

  };
  return (
    <div>
      <div className="ToggleIdNumber">
        <button onClick={() => masked ? setShowModal(true) : toggleMask()} className='btn__icon'>
          {masked ? <EyeIcon fill='#1956A8' width={18} /> : <EyeClosedIcon fill='#1956A8' width={18} />}
        </button>
        <span>{maskedNumber}</span>
      </div>
      {showModal && (
        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          title="Afficher le numéro du document"
          labelOnConfirm="Afficher"
          onConfirm={handleConfirm}
          confirmButtonDisabled={!isPasswordValid}
        >
          <p>Pour afficher le numéro du document, veuillez saisir votre mot de passe.</p>
          <Input placeholder="Mot de passe" type="password" value={password} onChange={handlePasswordChange} />
        </Modal>
      )}
    </div>
  );
}

export default ToggleIdNumber;
