import "./style.scss";

import { FC, useState } from "react";
import { FEATURES, Pricing } from "../../constants/pricings";

import { Link } from "react-router-dom";
import Modal from "../Modal";
import PriceCardDetail from "../PriceCardDetail";

interface RadioButtonPricingProps {
    plan: Pricing;
    setDatas: (data: any) => void;
    datas: any;
}

const RadioButtonPricing: FC<RadioButtonPricingProps> = ({ plan, setDatas, datas }) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    const handleRadioChange = () => {
        setDatas({
            ...datas,
            chosenPlan: plan,
        });
    };

    let defaultCheck = false;
    if (datas.chosenPlan.title === plan.title) {
        defaultCheck = true;
    }

    return (
        <>
            <label
                htmlFor={"checkPricing" + plan.title}
                className="pointer">
                <div className="RadioButtonPricing d-flex flex-row align-items-center justify-content-between">
                    <div className="d-flex justify-content-between">
                        <input
                            type="radio"
                            name="RadioButtonPricing"
                            id={"checkPricing" + plan.title}
                            className="large-radio"
                            defaultChecked={defaultCheck}
                            onChange={handleRadioChange}
                            data-testid="plan-radio-button"
                        />
                        <div className="d-flex flex-column">
                            <span>{plan.title}</span>
                            <span>{plan.description}</span>
                            <Link
                                to={"#"}
                                className="link"
                                onClick={() => {
                                    setShowModal(true);
                                }}>
                                <u>en savoir plus</u>
                            </Link>
                        </div>
                    </div>
                    <div className="price">{plan.price} €</div>
                </div>
            </label>
            {showModal && (
                <Modal
                    onClose={() => setShowModal(false)}
                    isOpen={showModal}
                    title={plan?.head}
                    infoModal={true}
                    type={"info"}>
                    <PriceCardDetail
                        plan={plan}
                        features={FEATURES}
                    />
                </Modal>
            )}
        </>
    );
};

export default RadioButtonPricing;
